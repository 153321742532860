import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Layout, 
  Sparkles, 
  Star, 
  Zap, 
  Flame, 
  Wand2, 
  Loader,
  Plus,
  X,
  Tag
} from 'lucide-react';
import { FormData } from '../../types/form';
import { generateBrandSuggestion } from '../../services/openai';

interface Props {
  formData: FormData;
  updateFormData: (field: keyof FormData, value: any) => void;
  maxCategories?: number;
  maxBanners?: number;
}

interface BannerContent {
  id: string;
  type: string;
  content: string;
  icon: any;
  placeholder: string;
}

const allBanners: BannerContent[] = [
  {
    id: '1',
    type: 'featured',
    content: '',
    icon: Star,
    placeholder: 'Ex: Descubra nossa nova coleção exclusiva...'
  },
  {
    id: '2',
    type: 'promotions',
    content: '',
    icon: Zap,
    placeholder: 'Ex: Oferta especial por tempo limitado...'
  },
  {
    id: '3',
    type: 'collections',
    content: '',
    icon: Layout,
    placeholder: 'Ex: Explore nossa coleção premium...'
  },
  {
    id: '4',
    type: 'seasonal',
    content: '',
    icon: Flame,
    placeholder: 'Ex: Tendências da nova estação...'
  }
];

export const DesignElements: React.FC<Props> = ({ 
  formData, 
  updateFormData, 
  maxCategories = 8,
  maxBanners = 4
}) => {
  const [banners, setBanners] = useState<BannerContent[]>(allBanners.slice(0, maxBanners));
  const [isGenerating, setIsGenerating] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [categoryError, setCategoryError] = useState('');

  const categories = formData.categories || [];

  const updateBannerContent = (id: string, content: string) => {
    const updatedBanners = banners.map(banner =>
      banner.id === id ? { ...banner, content } : banner
    );
    setBanners(updatedBanners);
    updateFormData('bannerContent', updatedBanners);
  };

  const generateBannerContent = async (id: string) => {
    const banner = banners.find(b => b.id === id);
    if (!banner || isGenerating) return;

    setIsGenerating(true);
    try {
      const suggestion = await generateBrandSuggestion('banner', {
        nicheMarket: formData.nicheMarket || '',
        targetAudience: formData.targetAudience || '',
        storeFocus: formData.storeFocus,
        bannerType: banner.type
      });

      const updatedBanners = banners.map(b =>
        b.id === id ? { ...b, content: suggestion.content } : b
      );
      setBanners(updatedBanners);
      updateFormData('bannerContent', updatedBanners);
    } catch (error) {
      console.error('Error generating banner content:', error);
    }
    setIsGenerating(false);
  };

  const handleAddCategory = (e: React.FormEvent) => {
    e.preventDefault();
    setCategoryError('');

    if (!newCategory.trim()) {
      return;
    }

    if (categories.length >= maxCategories) {
      setCategoryError(`Máximo de ${maxCategories} categorias atingido`);
      return;
    }

    if (categories.includes(newCategory.trim())) {
      setCategoryError('Esta categoria já existe');
      return;
    }

    updateFormData('categories', [...categories, newCategory.trim()]);
    setNewCategory('');
  };

  const removeCategory = (category: string) => {
    updateFormData('categories', categories.filter(c => c !== category));
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-8"
    >
      {/* Categories Section - Only show if maxCategories > 0 */}
      {maxCategories > 0 && (
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-bold text-white flex items-center gap-2">
              <Tag className="w-5 h-5 text-blue-400" />
              Categorias da Loja
            </h2>
            <span className="text-sm text-gray-400">
              {categories.length}/{maxCategories} categorias
            </span>
          </div>

          <form onSubmit={handleAddCategory} className="space-y-4">
            <div>
              <div className="relative">
                <input
                  type="text"
                  value={newCategory}
                  onChange={(e) => setNewCategory(e.target.value)}
                  className="w-full px-4 py-3 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-white placeholder-gray-400 pr-24"
                  placeholder="Digite uma categoria..."
                  maxLength={50}
                />
                <button
                  type="submit"
                  disabled={categories.length >= maxCategories || !newCategory.trim()}
                  className="absolute right-2 top-1/2 -translate-y-1/2 px-3 py-1 rounded-md bg-blue-500/20 text-blue-400 hover:bg-blue-500/30 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-1"
                >
                  <Plus className="w-4 h-4" />
                  <span>Adicionar</span>
                </button>
              </div>
              {categoryError && (
                <p className="mt-2 text-sm text-red-400">{categoryError}</p>
              )}
            </div>

            <div className="flex flex-wrap gap-2">
              {categories.map((category) => (
                <motion.div
                  key={category}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  className="group flex items-center gap-2 px-3 py-1.5 rounded-full bg-gray-700/50 border border-gray-600"
                >
                  <span className="text-sm text-white">{category}</span>
                  <button
                    type="button"
                    onClick={() => removeCategory(category)}
                    className="w-4 h-4 rounded-full flex items-center justify-center text-gray-400 hover:text-red-400 transition-colors"
                  >
                    <X className="w-3 h-3" />
                  </button>
                </motion.div>
              ))}
            </div>
          </form>
        </div>
      )}

      {/* Banners Section */}
      <div className="space-y-6">
        <h2 className="text-xl font-bold text-white flex items-center gap-2">
          <Layout className="w-5 h-5 text-blue-400" />
          Banners Principais
        </h2>
        
        <div className="space-y-6">
          {banners.map(banner => {
            const Icon = banner.icon;
            return (
              <div key={banner.id} className="space-y-2">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Icon className="w-5 h-5 text-blue-400" />
                    <label className="text-sm font-medium text-gray-300">
                      {banner.type === 'featured' ? 'Banner Destaque' :
                       banner.type === 'promotions' ? 'Banner Promocional' :
                       banner.type === 'collections' ? 'Banner Coleções' :
                       'Banner Sazonal'}
                    </label>
                  </div>
                  <button
                    onClick={() => generateBannerContent(banner.id)}
                    disabled={isGenerating}
                    className="flex items-center gap-1 text-sm text-blue-400 hover:text-blue-300 transition-colors"
                  >
                    {isGenerating ? (
                      <>
                        <Loader className="w-4 h-4 animate-spin" />
                        <span>Gerando...</span>
                      </>
                    ) : (
                      <>
                        <Wand2 className="w-4 h-4" />
                        <span>Sugestão da IA</span>
                      </>
                    )}
                  </button>
                </div>
                <div className="relative">
                  <textarea
                    value={banner.content}
                    onChange={(e) => updateBannerContent(banner.id, e.target.value)}
                    rows={2}
                    placeholder={banner.placeholder}
                    className="w-full px-4 py-3 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-white placeholder-gray-400"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </motion.div>
  );
};