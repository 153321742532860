import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '../../lib/utils';
import type { Rank } from '../../types/team';

interface Props {
  name: string;
  rank: Rank;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

const RANK_COLORS = {
  iron: {
    bg: 'from-gray-700 to-gray-800',
    border: 'border-gray-600',
    glow: 'shadow-gray-500/20'
  },
  silver: {
    bg: 'from-gray-400 to-gray-600',
    border: 'border-gray-300',
    glow: 'shadow-gray-400/30'
  },
  gold: {
    bg: 'from-yellow-500 to-amber-700',
    border: 'border-yellow-400',
    glow: 'shadow-yellow-500/30'
  },
  diamond: {
    bg: 'from-blue-400 to-blue-600',
    border: 'border-blue-300',
    glow: 'shadow-blue-500/30'
  }
};

const BACKGROUND_COLORS = [
  'from-blue-500 to-blue-700',
  'from-purple-500 to-purple-700',
  'from-green-500 to-green-700',
  'from-red-500 to-red-700',
  'from-pink-500 to-pink-700',
  'from-indigo-500 to-indigo-700',
  'from-cyan-500 to-cyan-700',
  'from-teal-500 to-teal-700'
];

export const InitialsAvatar: React.FC<Props> = ({ 
  name, 
  rank, 
  size = 'md',
  className 
}) => {
  // Generate consistent color based on name
  const colorIndex = name.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) % BACKGROUND_COLORS.length;
  const bgGradient = BACKGROUND_COLORS[colorIndex];

  const initials = name
    .split(' ')
    .map(word => word[0])
    .slice(0, 2)
    .join('')
    .toUpperCase();

  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      className={cn(
        "relative rounded-lg overflow-hidden",
        "bg-gradient-to-br shadow-lg transition-all",
        bgGradient,
        RANK_COLORS[rank].glow,
        size === 'sm' && "w-8 h-8 text-sm",
        size === 'md' && "w-12 h-12 text-lg",
        size === 'lg' && "w-16 h-16 text-2xl",
        className
      )}
    >
      {/* Rank Border */}
      <div className={cn(
        "absolute inset-0 border-2 rounded-lg",
        RANK_COLORS[rank].border,
        "bg-gradient-to-br opacity-50",
        RANK_COLORS[rank].bg
      )} />

      {/* Initials */}
      <div className="absolute inset-0 flex items-center justify-center font-bold text-white">
        {initials}
      </div>

      {/* Shine Effect */}
      <div className="absolute inset-0 bg-gradient-to-br from-white/20 to-transparent opacity-50" />
    </motion.div>
  );
};