import React, { useState, useMemo, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Search,
  Filter,
  Plus,
  Calendar,
  Edit2,
  Clock,
  AlertCircle,
  Users,
  CheckCircle2,
  ArrowUpDown,
  Loader2,
  Store
} from 'lucide-react';
import { format, addDays, isBefore, isAfter, isValid } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useProductionStore } from '../../stores/productionStore';
import { getAllStores } from '../../services/production';
import { EditStoreModal } from './EditStoreModal';
import { NewStoreModal } from './NewStoreModal';
import { cn } from '../../lib/utils';

type Tab = 'production' | 'completed';
type SortField = 'date' | 'name' | 'type';
type SortOrder = 'asc' | 'desc';

export const ProductionDashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState<Tab>('production');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStore, setSelectedStore] = useState<string | null>(null);
  const [showNewModal, setShowNewModal] = useState(false);
  const [sortField, setSortField] = useState<SortField>('date');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [filters, setFilters] = useState({
    type: '',
    dateRange: '',
    status: '',
    plan: ''
  });
  
  const { items, addItem, loading, error, fetchItems } = useProductionStore();

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const isStoreCompleted = (item: typeof items[0]) => {
    return Object.values(item.status).every(status => status === 'completed');
  };

  // Função de utilidade para validar e converter datas
  const parseDate = (dateString: string | null | undefined): Date => {
    if (!dateString) return new Date();
    try {
      const date = new Date(dateString);
      return isValid(date) ? date : new Date();
    } catch {
      return new Date();
    }
  };

  const filteredAndSortedItems = useMemo(() => {
    // First, filter items based on tab
    let filtered = items.filter(item => {
      const isCompleted = isStoreCompleted(item);
      return activeTab === 'completed' ? isCompleted : !isCompleted;
    });

    // Apply search and filters
    filtered = filtered.filter(item => {
      if (searchTerm && !item.storeName.toLowerCase().includes(searchTerm.toLowerCase())) {
        return false;
      }
      if (filters.type && item.storeType !== filters.type) {
        return false;
      }
      if (filters.plan && item.plan !== filters.plan) {
        return false;
      }
      if (filters.assignedTo && item.assignedTo !== filters.assignedTo) {
        return false;
      }
      if (filters.dateRange && filters.dateRange !== 'all') {
        const date = new Date(item.purchaseDate);
        const today = new Date();
        switch (filters.dateRange) {
          case 'today':
            return format(date, 'yyyy-MM-dd') === format(today, 'yyyy-MM-dd');
          case 'week':
            const weekAgo = addDays(today, -7);
            return isAfter(date, weekAgo);
          case 'month':
            const monthAgo = addDays(today, -30);
            return isAfter(date, monthAgo);
        }
      }
      if (filters.status) {
        return Object.values(item.status).some(status => status === filters.status);
      }
      return true;
    });

    // Sort items
    return filtered.sort((a, b) => {
      let comparison = 0;
      switch (sortField) {
        case 'date':
          comparison = new Date(b.purchaseDate).getTime() - new Date(a.purchaseDate).getTime();
          break;
        case 'name':
          comparison = a.storeName.localeCompare(b.storeName);
          break;
        case 'type':
          comparison = a.storeType.localeCompare(b.storeType);
          break;
      }
      return sortOrder === 'asc' ? comparison : -comparison;
    });
  }, [items, activeTab, searchTerm, filters, sortField, sortOrder]);

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortOrder(order => order === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  if (error) {
    return (
      <div className="flex items-center gap-2 p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400">
        <AlertCircle className="w-5 h-5" />
        <span>{error}</span>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold text-white">Produção</h2>
        <button 
          onClick={() => setShowNewModal(true)}
          className="flex items-center gap-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors"
        >
          <Plus className="w-4 h-4" />
          Nova Loja
        </button>
      </div>

      {/* Tabs */}
      <div className="flex items-center gap-4 border-b border-gray-700">
        <button
          onClick={() => setActiveTab('production')}
          className={cn(
            "px-4 py-2 -mb-px transition-colors",
            activeTab === 'production'
              ? "text-blue-400 border-b-2 border-blue-400"
              : "text-gray-400 hover:text-gray-300"
          )}
        >
          Em Produção
        </button>
        <button
          onClick={() => setActiveTab('completed')}
          className={cn(
            "px-4 py-2 -mb-px transition-colors",
            activeTab === 'completed'
              ? "text-blue-400 border-b-2 border-blue-400"
              : "text-gray-400 hover:text-gray-300"
          )}
        >
          Concluídas
        </button>
      </div>

      {/* Filters */}
      <div className="flex flex-wrap gap-4 mb-6">
        {/* Search */}
        <div className="flex-1 min-w-[240px]">
          <div className="relative">
            <Search className="w-4 h-4 absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Buscar loja..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:border-blue-500"
            />
          </div>
        </div>

        {/* Plan Filter */}
        <div className="min-w-[180px]">
          <select
            value={filters.plan}
            onChange={(e) => setFilters(prev => ({ ...prev, plan: e.target.value }))}
            className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:outline-none focus:border-blue-500"
          >
            <option value="">Todos os Planos</option>
            <option value="start">Start</option>
            <option value="premium">Premium</option>
            <option value="branding">Branding</option>
          </select>
        </div>

        {/* Type Filter */}
        <div className="min-w-[180px]">
          <select
            value={filters.type}
            onChange={(e) => setFilters({ ...filters, type: e.target.value })}
            className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:outline-none focus:border-blue-500"
          >
            <option value="">Tipo</option>
            <option value="generic">Loja Geral</option>
            <option value="niche">Loja de Nicho</option>
          </select>
        </div>

        {/* Date Range Filter */}
        <div className="min-w-[180px]">
          <select
            value={filters.dateRange}
            onChange={(e) => setFilters({ ...filters, dateRange: e.target.value })}
            className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:outline-none focus:border-blue-500"
          >
            <option value="">Data</option>
            <option value="all">Todas as datas</option>
            <option value="today">Hoje</option>
            <option value="week">Última Semana</option>
            <option value="month">Último Mês</option>
          </select>
        </div>

        {/* Status Filter */}
        <div className="min-w-[180px]">
          <select
            value={filters.status}
            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
            className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:outline-none focus:border-blue-500"
          >
            <option value="">Status</option>
            <option value="pending">Pendente</option>
            <option value="in_progress">Em Andamento</option>
            <option value="completed">Concluído</option>
          </select>
        </div>
      </div>

      {/* Table */}
      <div className="glass-card overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b border-gray-700">
                <th className="px-6 py-4 text-left">
                  <button
                    onClick={() => handleSort('name')}
                    className="flex items-center gap-2 text-sm font-medium text-gray-400 hover:text-white"
                  >
                    Loja
                    <ArrowUpDown className="w-4 h-4" />
                  </button>
                </th>
                <th className="px-6 py-4 text-left">
                  <button
                    onClick={() => handleSort('type')}
                    className="flex items-center gap-2 text-sm font-medium text-gray-400 hover:text-white"
                  >
                    Tipo
                    <ArrowUpDown className="w-4 h-4" />
                  </button>
                </th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-400">Status</th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-400">Responsável</th>
                <th className="px-6 py-4 text-left">
                  <button
                    onClick={() => handleSort('date')}
                    className="flex items-center gap-2 text-sm font-medium text-gray-400 hover:text-white"
                  >
                    Data
                    <ArrowUpDown className="w-4 h-4" />
                  </button>
                </th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-400">Ações</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {loading ? (
                <tr>
                  <td colSpan={6} className="py-8 text-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
                  </td>
                </tr>
              ) : filteredAndSortedItems.length === 0 ? (
                <tr>
                  <td colSpan={6} className="py-8 text-center text-gray-400">
                    Nenhuma loja encontrada
                  </td>
                </tr>
              ) : (
                filteredAndSortedItems.map((item) => {
                  const purchaseDate = parseDate(item.purchaseDate);
                  const deliveryDate = parseDate(item.expectedDeliveryDate) || addDays(purchaseDate, 15);
                  const now = new Date();
                  const isOverdue = isValid(deliveryDate) && isBefore(deliveryDate, now);
                  const isDueSoon = isValid(deliveryDate) && isAfter(deliveryDate, now) && isBefore(deliveryDate, addDays(now, 2));

                  return (
                    <tr key={item.id} className="hover:bg-gray-800/50 transition-colors">
                      <td className="px-6 py-4">
                        <div className="font-medium text-white">{item.storeName}</div>
                      </td>
                      <td className="px-6 py-4">
                        <span className="text-gray-300">
                          {item.storeType === 'generic' ? 'Loja Geral' : 'Loja de Nicho'}
                        </span>
                      </td>
                      <td className="px-6 py-4">
                        {activeTab === 'completed' ? (
                          <span className="inline-flex items-center gap-1 px-3 py-1 rounded-full text-xs bg-green-500/20 text-green-300">
                            <CheckCircle2 className="w-3 h-3" />
                            Concluída
                          </span>
                        ) : (
                          <div className="flex flex-wrap gap-1">
                            {Object.entries(item.status).map(([stage, status]) => (
                              <div
                                key={`${item.id}-${stage}`}
                                className={cn(
                                  "w-2 h-2 rounded-full",
                                  status === 'completed' && "bg-green-500",
                                  status === 'in_progress' && "bg-blue-500",
                                  status === 'pending' && "bg-gray-500"
                                )}
                                title={`${stage}: ${status}`}
                              />
                            ))}
                          </div>
                        )}
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex items-center gap-2">
                          <Users className="w-4 h-4 text-gray-400" />
                          <span className="text-gray-300">
                            {item.assignedTo || 'Não atribuído'}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className={cn(
                          "flex items-center gap-2",
                          isOverdue ? "text-red-400" :
                          isDueSoon ? "text-yellow-400" :
                          "text-gray-400"
                        )}>
                          <Calendar className="w-4 h-4" />
                          <span>{isValid(deliveryDate) ? format(deliveryDate, "dd/MM/yyyy") : "Data inválida"}</span>
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <button
                          onClick={() => setSelectedStore(item.id)}
                          className="p-2 rounded-lg text-gray-400 hover:text-white hover:bg-gray-700/50 transition-colors"
                        >
                          <Edit2 className="w-4 h-4" />
                        </button>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modals */}
      {selectedStore && (
        <EditStoreModal
          item={items.find(i => i.id === selectedStore)!}
          onClose={() => setSelectedStore(null)}
        />
      )}

      {showNewModal && (
        <NewStoreModal onClose={() => setShowNewModal(false)} />
      )}
    </div>
  );
};