import { AutomationEvent, AutomationResult, AutomationRule } from './types';
import { defaultRules } from './rules';
import { whatsAppService } from '../whatsapp';
import { useNotificationStore } from '../../stores/notificationStore';
import { useTimelineStore } from '../../stores/timelineStore';

class AutomationService {
  private rules: AutomationRule[] = defaultRules;

  async processEvent(event: AutomationEvent): Promise<AutomationResult> {
    try {
      const applicableRules = this.rules.filter(
        rule => rule.enabled && rule.trigger === event.type
      );

      for (const rule of applicableRules) {
        await this.executeActions(rule, event.data);
      }

      return { success: true };
    } catch (error) {
      console.error('Error processing automation:', error);
      return {
        success: false,
        message: error instanceof Error ? error.message : 'Unknown error'
      };
    }
  }

  private async executeActions(rule: AutomationRule, data: any): Promise<void> {
    for (const action of rule.actions) {
      switch (action.type) {
        case 'whatsapp':
          await whatsAppService.sendMessage(
            data.whatsapp,
            action.config.template,
            data
          );
          break;

        case 'notification':
          useNotificationStore.getState().addNotification({
            title: action.config.title,
            message: this.formatMessage(action.config.message, data),
            type: action.config.type
          });
          break;

        case 'timeline':
          useTimelineStore.getState().addEvent({
            storeId: data.storeId,
            storeName: data.storeName,
            type: action.config.type,
            title: action.config.title,
            description: this.formatMessage(action.config.description, data),
            user: 'Sistema'
          });
          break;
      }
    }
  }

  private formatMessage(template: string, data: any): string {
    return template.replace(/\{(\w+)\}/g, (_, key) => data[key] || '');
  }

  addRule(rule: AutomationRule): void {
    this.rules.push(rule);
  }

  removeRule(id: string): void {
    this.rules = this.rules.filter(rule => rule.id !== id);
  }

  toggleRule(id: string): void {
    this.rules = this.rules.map(rule =>
      rule.id === id ? { ...rule, enabled: !rule.enabled } : rule
    );
  }

  getRules(): AutomationRule[] {
    return this.rules;
  }
}

export const automationService = new AutomationService();