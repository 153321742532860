import { supabase } from '../lib/supabase';
import { useProductionStore } from '../stores/productionStore';
import type { FormData } from '../types/form';
import { createProductionItem } from './database/production';

// Status padrão para novas lojas
const defaultStatus = {
  visualIdentity: 'pending',
  banners: 'pending',
  information: 'pending',
  collectionCovers: 'pending',
  socialMedia: 'pending',
  motion: 'pending',
  checkout: 'pending',
  refinement: 'pending',
  storeConfig: 'pending'
};

// Função simples para criar loja
export const createStore = async (formData: FormData) => {
  try {
    const store = await createProductionItem(formData);
    
    // Atualizar o store local
    const productionStore = useProductionStore.getState();
    productionStore.addItem({
      id: store.id,
      storeName: store.store_name,
      storeType: store.store_type,
      platform: store.platform,
      plan: store.plan,
      status: store.status,
      assignedTo: store.assigned_to,
      driveLink: store.drive_link,
      purchaseDate: store.purchase_date,
      completedStoreLink: store.completed_store_link,
      lastUpdate: store.updated_at,
      revisions: store.revisions || 0,
      tags: store.tags || [],
      priority: store.priority,
      client: store.client,
      paymentStatus: store.payment_status,
      paymentValue: store.payment_value,
      paymentMethod: store.payment_method,
      customRequirements: store.custom_requirements,
      responsibleDesigner: store.responsible_designer,
      responsibleDeveloper: store.responsible_developer,
      expectedDeliveryDate: store.expected_delivery_date,
      integrations: store.integrations || []
    });

    return store;
  } catch (error) {
    console.error('Error in createStore:', error);
    if (error instanceof Error) {
      throw error;
    } else {
      throw new Error('Erro ao criar loja');
    }
  }
};

// Função para buscar todas as lojas
export const getAllStores = async () => {
  try {
    const { data, error } = await supabase
      .from('store_production')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) throw error;

    // Atualizar store local
    if (data) {
      const productionStore = useProductionStore.getState();
      const formattedItems = data.map(item => ({
        id: item.id,
        storeName: item.store_name,
        storeType: item.store_type,
        platform: item.platform,
        status: item.status,
        assignedTo: item.assigned_to,
        purchaseDate: item.purchase_date,
        lastUpdate: item.updated_at,
        revisions: item.revisions,
        tags: item.tags || [],
        priority: item.priority,
        client: item.client,
        expectedDeliveryDate: item.expected_delivery_date
      }));
      
      // Usar setItems ao invés de addItem para cada item
      productionStore.setItems(formattedItems);
    }

    return data;
  } catch (error) {
    console.error('Erro ao buscar lojas:', error);
    throw error;
  }
};

// Função para atualizar o status de uma loja
export const updateStoreStatus = async (storeId: string, updates: Partial<ProductionItem>) => {
  try {
    // Preparar os dados para atualização
    const updateData = {
      ...updates,
      updated_at: new Date().toISOString()
    };

    // Converter nomes de campos de camelCase para snake_case
    const snakeCaseData = {
      store_name: updateData.storeName,
      store_type: updateData.storeType,
      platform: updateData.platform,
      plan: updateData.plan,
      status: updateData.status,
      assigned_to: updateData.assignedTo,
      drive_link: updateData.driveLink,
      purchase_date: updateData.purchaseDate,
      completed_store_link: updateData.completedStoreLink,
      updated_at: updateData.updated_at,
      revisions: updateData.revisions,
      tags: updateData.tags,
      priority: updateData.priority,
      client: updateData.client,
      payment_status: updateData.paymentStatus,
      payment_value: updateData.paymentValue,
      payment_method: updateData.paymentMethod,
      custom_requirements: updateData.customRequirements,
      responsible_designer: updateData.responsibleDesigner,
      responsible_developer: updateData.responsibleDeveloper,
      expected_delivery_date: updateData.expectedDeliveryDate,
      integrations: updateData.integrations
    };

    // Remover campos undefined
    Object.keys(snakeCaseData).forEach(key => {
      if (snakeCaseData[key] === undefined) {
        delete snakeCaseData[key];
      }
    });

    // Atualizar no Supabase
    const { data, error } = await supabase
      .from('store_production')
      .update(snakeCaseData)
      .eq('id', storeId)
      .select()
      .single();

    if (error) {
      console.error('Erro ao atualizar loja:', error);
      throw new Error(`Erro ao atualizar loja: ${error.message}`);
    }

    if (!data) {
      throw new Error('Nenhum dado retornado ao atualizar loja');
    }

    // Atualizar o store local
    const productionStore = useProductionStore.getState();
    productionStore.updateItem(storeId, {
      id: data.id,
      storeName: data.store_name,
      storeType: data.store_type,
      platform: data.platform,
      plan: data.plan,
      status: data.status,
      assignedTo: data.assigned_to,
      driveLink: data.drive_link,
      purchaseDate: data.purchase_date,
      completedStoreLink: data.completed_store_link,
      lastUpdate: data.updated_at,
      revisions: data.revisions || 0,
      tags: data.tags || [],
      priority: data.priority,
      client: data.client,
      paymentStatus: data.payment_status,
      paymentValue: data.payment_value,
      paymentMethod: data.payment_method,
      customRequirements: data.custom_requirements,
      responsibleDesigner: data.responsible_designer,
      responsibleDeveloper: data.responsible_developer,
      expectedDeliveryDate: data.expected_delivery_date,
      integrations: data.integrations || []
    });

    return data;
  } catch (error) {
    console.error('Erro ao atualizar loja:', error);
    if (error instanceof Error) {
      throw error;
    } else {
      throw new Error('Erro desconhecido ao atualizar loja');
    }
  }
};