import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Store,
  Palette,
  User,
  Settings,
  Globe2,
  MessageSquare,
  CreditCard,
  Link,
  Phone,
  Layout,
  Target,
  ShoppingBag,
  CheckSquare,
  Mail,
  Copy,
  Check
} from 'lucide-react';

interface ResponseDetailsProps {
  response: any;
}

const ColorBox: React.FC<{ color: string; label: string }> = ({ color, label }) => {
  const [copied, setCopied] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(color);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <div className="flex flex-col gap-1">
      <motion.button
        onClick={handleCopy}
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
        className="relative w-full h-12 rounded-lg border border-gray-600 cursor-pointer overflow-hidden group"
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        <div 
          className="absolute inset-0"
          style={{ backgroundColor: color }}
        />
        
        <AnimatePresence>
          {isHovered && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute inset-0 flex items-center justify-center bg-black/40"
            >
              {copied ? (
                <Check className="w-5 h-5 text-green-400" />
              ) : (
                <Copy className="w-5 h-5 text-white" />
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.button>
      <div className="flex justify-between text-sm">
        <span className="text-gray-400">{label}</span>
        <span className="text-white">{color}</span>
      </div>
    </div>
  );
};

export const ResponseDetails: React.FC<ResponseDetailsProps> = ({ response }) => {
  const data = response.response_data;

  const renderColorBox = (color: string, label: string) => (
    <ColorBox color={color} label={label} />
  );

  const renderBanner = (banner: any) => {
    if (!banner?.content) return null;
    return (
      <div className="space-y-1">
        <span className="text-sm text-gray-400">
          {banner.type === 'featured' ? 'Banner Destaque' :
           banner.type === 'promotions' ? 'Banner Promocional' :
           banner.type === 'collections' ? 'Banner Coleções' :
           'Banner Sazonal'}
        </span>
        <p className="text-white text-sm bg-gray-700/50 p-3 rounded-lg">
          {banner.content}
        </p>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      {/* Store Identity */}
      <div className="glass-card p-6 space-y-6">
        <div className="flex items-center gap-2">
          <Store className="w-5 h-5 text-blue-400" />
          <h3 className="text-lg font-medium text-white">Identidade da Loja</h3>
        </div>
        <div className="space-y-6">
          <div className="flex flex-col gap-1">
            <span className="text-sm text-gray-400">Nome da Loja</span>
            <span className="text-2xl font-medium text-white">{data.storeName}</span>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col gap-1">
              <span className="text-sm text-gray-400">Tipo</span>
              <span className="text-white">{data.storeFocus}</span>
            </div>
            {data.nicheMarket && (
              <div className="flex flex-col gap-1">
                <span className="text-sm text-gray-400">Nicho</span>
                <span className="text-white">{data.nicheMarket}</span>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-sm text-gray-400">Localização</span>
            <div className="flex items-center gap-2 bg-gray-700/50 px-3 py-2 rounded-lg">
              <Globe2 className="w-4 h-4 text-blue-400" />
              <span className="text-white">
                {data.customRegion || data.region}
              </span>
            </div>
          </div>
          {data.referenceStore && (
            <div className="flex flex-col gap-2">
              <span className="text-sm text-gray-400">Loja de Referência</span>
              <a
                href={data.referenceStore}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 bg-gray-700/50 px-3 py-2 rounded-lg text-blue-400 hover:text-blue-300 transition-colors"
              >
                <Link className="w-4 h-4" />
                <span className="truncate">{data.referenceStore}</span>
              </a>
            </div>
          )}
        </div>
      </div>

      {/* Brand Style */}
      <div className="glass-card p-6 space-y-6">
        <div className="flex items-center gap-2">
          <Palette className="w-5 h-5 text-blue-400" />
          <h3 className="text-lg font-medium text-white">Estilo da Marca</h3>
        </div>
        <div className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
            {renderColorBox(data.colors.primary, 'Cor Principal')}
            {renderColorBox(data.colors.accent, 'Cor Destaque')}
            {data.colors.tertiary && (
              <div className="col-span-2">
                {renderColorBox(data.colors.tertiary, 'Cor Terciária')}
              </div>
            )}
          </div>
          <div className="space-y-2">
            <span className="text-sm text-gray-400">Personalidade</span>
            <div className="flex flex-wrap gap-2">
              {data.personality.map((trait: string) => (
                <span 
                  key={trait}
                  className="px-3 py-1 rounded-full bg-blue-500/20 text-blue-300 text-sm"
                >
                  {trait}
                </span>
              ))}
            </div>
          </div>
          <div className="space-y-2">
            <span className="text-sm text-gray-400">Representação Visual</span>
            <div className="bg-gray-700/50 px-3 py-2 rounded-lg text-white">
              {data.brandStyle === '3d-mascot' ? 'Mascote 3D' : 'Modelo Humano'}
            </div>
            {data.brandDescription && (
              <p className="text-sm text-gray-300 mt-2">{data.brandDescription}</p>
            )}
          </div>
          {data.bannerContent && (
            <div className="space-y-4">
              <span className="text-sm text-gray-400">Banners</span>
              <div className="space-y-3">
                {data.bannerContent.map((banner: any, index: number) => (
                  <div key={index}>
                    {renderBanner(banner)}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Client & Settings */}
      <div className="glass-card p-6 space-y-6">
        <div className="flex items-center gap-2">
          <User className="w-5 h-5 text-blue-400" />
          <h3 className="text-lg font-medium text-white">Cliente e Configurações</h3>
        </div>
        <div className="space-y-6">
          {/* Personal Info */}
          <div className="space-y-4">
            <div className="flex flex-col gap-1">
              <span className="text-sm text-gray-400">Nome Completo</span>
              <span className="text-xl font-medium text-white">{data.fullName}</span>
            </div>
            <div className="flex items-center gap-2 bg-gray-700/50 px-3 py-2 rounded-lg">
              <Mail className="w-4 h-4 text-gray-400" />
              <span className="text-white">{data.email}</span>
            </div>
            <div className="flex items-center gap-2 bg-gray-700/50 px-3 py-2 rounded-lg">
              <Phone className="w-4 h-4 text-gray-400" />
              <span className="text-white">{data.whatsapp}</span>
            </div>
          </div>

          {/* Payment Settings */}
          {data.paymentGateways && (
            <div className="space-y-2">
              <span className="text-sm text-gray-400">Gateways de Pagamento</span>
              <div className="flex flex-wrap gap-2">
                {data.paymentGateways.map((gateway: string) => (
                  <div 
                    key={gateway}
                    className="flex items-center gap-2 px-3 py-2 rounded-lg bg-gray-700/50"
                  >
                    <CreditCard className="w-4 h-4 text-blue-400" />
                    <span className="text-white">{gateway}</span>
                  </div>
                ))}
              </div>
              {data.otherGateway && (
                <div className="mt-2 text-sm text-gray-300">
                  Outro gateway: {data.otherGateway}
                </div>
              )}
            </div>
          )}

          {/* Social Media */}
          {data.socialMedia && Object.entries(data.socialMedia).length > 0 && (
            <div className="space-y-2">
              <span className="text-sm text-gray-400">Redes Sociais</span>
              <div className="space-y-2">
                {Object.entries(data.socialMedia).map(([platform, url]) => (
                  url && (
                    <div key={platform} className="flex items-center gap-2 bg-gray-700/50 px-3 py-2 rounded-lg">
                      <Link className="w-4 h-4 text-blue-400" />
                      <a 
                        href={url as string}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white hover:text-blue-300 transition-colors"
                      >
                        {platform}
                      </a>
                    </div>
                  )
                ))}
              </div>
            </div>
          )}

          {/* Additional Settings */}
          <div className="space-y-4">
            {data.shopifyUrl && (
              <div className="flex flex-col gap-1">
                <span className="text-sm text-gray-400">URL da Loja</span>
                <a 
                  href={data.shopifyUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-300 transition-colors"
                >
                  {data.shopifyUrl}
                </a>
              </div>
            )}
            {data.additionalNotes && (
              <div className="space-y-2">
                <span className="text-sm text-gray-400">Observações Adicionais</span>
                <p className="text-white bg-gray-700/50 p-3 rounded-lg text-sm">
                  {data.additionalNotes}
                </p>
              </div>
            )}
            <div className="flex items-center gap-2 text-sm">
              <CheckSquare className="w-4 h-4 text-green-400" />
              <span className="text-gray-300">Termos aceitos</span>
            </div>
          </div>
        </div>
      </div>

      {/* Products & Categories */}
      <div className="glass-card p-6 space-y-6">
        <div className="flex items-center gap-2">
          <ShoppingBag className="w-5 h-5 text-blue-400" />
          <h3 className="text-lg font-medium text-white">Produtos e Categorias</h3>
        </div>
        <div className="space-y-6">
          <div className="space-y-2">
            <span className="text-sm text-gray-400">Produtos</span>
            <p className="text-white bg-gray-700/50 p-3 rounded-lg">
              {data.products}
            </p>
          </div>
          <div className="space-y-2">
            <span className="text-sm text-gray-400">Público-alvo</span>
            <p className="text-white bg-gray-700/50 p-3 rounded-lg">
              {data.targetAudience}
            </p>
          </div>
          {data.categories && data.categories.length > 0 && (
            <div className="space-y-2">
              <span className="text-sm text-gray-400">Categorias</span>
              <div className="flex flex-wrap gap-2">
                {data.categories.map((category: string) => (
                  <span 
                    key={category}
                    className="px-3 py-1 rounded-lg bg-gray-700/50 text-white text-sm"
                  >
                    {category}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};