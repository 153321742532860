import { WhatsAppMessage, WhatsAppStatus } from './types';
import { templates } from './templates';

class WhatsAppService {
  private formatPhoneNumber(phone: string): string {
    return phone.replace(/\D/g, '');
  }

  private generateWhatsAppUrl(phone: string, message: string): string {
    return `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;
  }

  async sendMessage(phone: string, template: string, data: any): Promise<WhatsAppStatus> {
    try {
      const templateConfig = templates[template];
      if (!templateConfig) {
        throw new Error('Template não encontrado');
      }

      const formattedPhone = this.formatPhoneNumber(phone);
      const message = templateConfig.format(data);
      const url = this.generateWhatsAppUrl(formattedPhone, message);

      // Em produção, aqui seria a integração real com a API do WhatsApp Business
      window.open(url, '_blank');
      
      return 'sent';
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
      return 'failed';
    }
  }
}

export const whatsAppService = new WhatsAppService();