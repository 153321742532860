import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { LoginPage } from './components/auth/LoginPage';
import { DashboardLayout } from './components/dashboard/DashboardLayout';
import { FormList } from './components/dashboard/FormList';
import { FormResponses } from './components/dashboard/FormResponses';
import { useAuthStore } from './stores/authStore';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MainForm } from './components/MainForm';
import { ProForm } from './components/ProForm';
import { StartForm } from './components/StartForm';
import { DashboardHome } from './components/dashboard/DashboardHome';
import { AutomationsList } from './components/dashboard/AutomationsList';
import { TeamManagement } from './components/dashboard/TeamManagement';
import { ProductionTable } from './components/dashboard/ProductionTable';
import { NotificationToast } from './components/notifications/NotificationToast';
import { NotificationSound } from './components/notifications/NotificationSound';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const checkSession = useAuthStore((state) => state.checkSession);

  useEffect(() => {
    checkSession();
  }, [checkSession]);

  if (isAuthenticated === null) {
    return <div>Carregando...</div>;
  }

  return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
};

const PublicRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  return isAuthenticated ? <Navigate to="/dashboard" /> : <>{children}</>;
};

const FormRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Forms são públicos, não precisam de autenticação
  return <>{children}</>;
};

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          {/* Public Routes - Forms */}
          <Route path="/" element={<Navigate to="/form" replace />} />
          <Route 
            path="/form" 
            element={
              <FormRoute>
                <MainForm />
              </FormRoute>
            } 
          />
          <Route 
            path="/form/premium" 
            element={
              <FormRoute>
                <ProForm />
              </FormRoute>
            } 
          />
          <Route 
            path="/form/start" 
            element={
              <FormRoute>
                <StartForm />
              </FormRoute>
            } 
          />

          {/* Login Route */}
          <Route 
            path="/login" 
            element={
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
            } 
          />

          {/* Protected Routes - Dashboard */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <DashboardLayout />
              </ProtectedRoute>
            }
          >
            <Route index element={<DashboardHome />} />
            <Route path="forms" element={<FormList />} />
            <Route path="forms/:formId/responses" element={<FormResponses />} />
            <Route path="automations" element={<AutomationsList />} />
            <Route path="team" element={<TeamManagement />} />
            <Route path="production" element={<ProductionTable />} />
          </Route>

          {/* Fallback route */}
          <Route path="*" element={<Navigate to="/form" replace />} />
        </Routes>
        <NotificationToast />
        <NotificationSound />
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;