import React from 'react';
import { motion } from 'framer-motion';
import { FormData } from '../../App';

interface Props {
  formData: FormData;
  updateFormData: (field: keyof FormData, value: any) => void;
}

const options = [
  {
    id: '3d-mascot',
    title: 'Mascote 3D',
    description: 'Perfeito para marcas divertidas e acessíveis',
    image: 'https://woopremium.site/wp-content/uploads/2024/11/Mascote.png'
  },
  {
    id: 'human-model',
    title: 'Modelo',
    description: 'Ideal para marcas profissionais e relacionáveis',
    image: 'https://woopremium.site/wp-content/uploads/2024/11/Modelo.png'
  }
];

export const BrandRepresentation: React.FC<Props> = ({ formData, updateFormData }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-8"
    >
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-white mb-3">
          Humanize sua Marca
        </h2>
        <p className="text-gray-400">
          Escolha como você gostaria de representar sua marca para criar uma conexão mais forte com seu público.
        </p>
      </div>

      <div className="grid grid-cols-2 gap-6">
        {options.map((option) => (
          <motion.button
            key={option.id}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => updateFormData('brandStyle', option.id)}
            className={`relative rounded-xl overflow-hidden cursor-pointer transition-all group ${
              formData.brandStyle === option.id
                ? 'ring-2 ring-[#2BA6FF] shadow-lg shadow-[#2BA6FF]/20'
                : 'border border-gray-700 hover:border-gray-600'
            }`}
          >
            <div className="relative w-full" style={{ aspectRatio: '870/1200' }}>
              <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent z-10" />
              <img
                src={option.image}
                alt={option.title}
                className="w-full h-full object-cover object-center transition-all duration-500 grayscale group-hover:grayscale-0"
                style={{ 
                  filter: formData.brandStyle === option.id ? 'grayscale(0)' : undefined
                }}
              />
              <div className="absolute bottom-0 left-0 right-0 p-6 z-20">
                <h3 className="font-medium text-xl text-white mb-2">{option.title}</h3>
                <p className="text-sm text-gray-200">{option.description}</p>
              </div>
            </div>
          </motion.button>
        ))}
      </div>

      {formData.brandStyle && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          className="space-y-4"
        >
          <div>
            <label className="block text-sm font-medium text-gray-300">
              {formData.brandStyle === '3d-mascot'
                ? "Descreva as características do seu mascote (opcional)"
                : 'Descreva o visual que você quer transmitir (opcional)'}
            </label>
            <p className="text-sm text-gray-400 mb-2">
              {formData.brandStyle === '3d-mascot'
                ? "Compartilhe detalhes sobre personalidade, cores e estilo"
                : 'Compartilhe detalhes sobre o estilo fotográfico e atmosfera desejada'}
            </p>
          </div>
          <textarea
            value={formData.brandDescription}
            onChange={(e) => updateFormData('brandDescription', e.target.value)}
            rows={4}
            className="input-modern"
            placeholder={
              formData.brandStyle === '3d-mascot'
                ? 'Ex: Um mascote amigável com cores vibrantes e personalidade divertida...'
                : 'Ex: Fotos clean e minimalistas com foco em lifestyle...'
            }
          />
        </motion.div>
      )}
    </motion.div>
  );
};