import { saveFormResponse } from './database/formResponses';
import { createStore } from './production';
import type { FormData } from '../types/form';

export const submitForm = async (formData: FormData, formId: number = 1): Promise<{ success: boolean; message: string }> => {
  try {
    // Save form response
    const formResponse = await saveFormResponse(formData, formId);
    if (!formResponse) {
      return { 
        success: false, 
        message: 'Erro ao salvar resposta do formulário' 
      };
    }

    try {
      // Create store
      const store = await createStore(formData);
      if (!store) {
        return { 
          success: true, 
          message: 'Formulário salvo, mas houve um erro ao criar a loja em produção' 
        };
      }
    } catch (storeError) {
      return { 
        success: true, 
        message: 'Formulário salvo, mas houve um erro ao criar a loja em produção' 
      };
    }

    return { 
      success: true, 
      message: 'Formulário enviado com sucesso!' 
    };
  } catch (error) {
    return { 
      success: false, 
      message: error instanceof Error ? error.message : 'Erro ao enviar formulário' 
    };
  }
};