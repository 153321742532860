import React from 'react';
import { motion } from 'framer-motion';
import { Store, CreditCard, Share2, FileText } from 'lucide-react';
import { FormData } from '../../App';

interface Props {
  formData: FormData;
  updateFormData: (field: keyof FormData, value: any) => void;
  onComplete: () => void;
}

const paymentGateways = [
  'Appmax',
  'CartPanda',
  'Cúpula Hub',
  'Outro'
];

export const StoreSettings: React.FC<Props> = ({ formData, updateFormData, onComplete }) => {
  const togglePaymentGateway = (gateway: string) => {
    const current = formData.paymentGateways || [];
    const updated = current.includes(gateway)
      ? current.filter(g => g !== gateway)
      : [...current, gateway];
    updateFormData('paymentGateways', updated);
  };

  const updateSocialMedia = (platform: string, url: string) => {
    updateFormData('socialMedia', {
      ...formData.socialMedia,
      [platform.toLowerCase()]: url
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-8"
    >
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-white mb-3">
          Configurações Finais
        </h2>
        <p className="text-gray-400">
          Vamos configurar os aspectos técnicos da sua loja
        </p>
      </div>

      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            URL da Loja Existente (Opcional)
          </label>
          <input
            type="url"
            value={formData.shopifyUrl || ''}
            onChange={(e) => updateFormData('shopifyUrl', e.target.value)}
            placeholder="https://sua-loja.com"
            className="input-modern"
          />
        </div>

        <div className="space-y-4">
          <h3 className="text-lg font-medium text-white flex items-center gap-2">
            <CreditCard className="w-5 h-5" />
            Gateway de Pagamento
          </h3>
          <div className="grid grid-cols-2 gap-3">
            {paymentGateways.map((gateway) => (
              <button
                key={gateway}
                onClick={() => togglePaymentGateway(gateway)}
                className={`p-4 rounded-lg border transition-all ${
                  formData.paymentGateways?.includes(gateway)
                    ? 'border-blue-500 bg-blue-500/20 text-white'
                    : 'border-gray-600 bg-gray-700/50 text-gray-300 hover:border-gray-500'
                }`}
              >
                {gateway}
              </button>
            ))}
          </div>

          {formData.paymentGateways?.includes('Outro') && (
            <input
              type="text"
              value={formData.otherGateway || ''}
              onChange={(e) => updateFormData('otherGateway', e.target.value)}
              placeholder="Nome do gateway de pagamento"
              className="input-modern mt-3"
            />
          )}
        </div>

        <div className="space-y-4">
          <h3 className="text-lg font-medium text-white flex items-center gap-2">
            <Share2 className="w-5 h-5" />
            Redes Sociais
          </h3>
          <div className="space-y-4">
            {['Instagram', 'Facebook', 'TikTok'].map((platform) => (
              <div key={platform}>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  {platform} (Opcional)
                </label>
                <input
                  type="url"
                  value={formData.socialMedia?.[platform.toLowerCase()] || ''}
                  onChange={(e) => updateSocialMedia(platform, e.target.value)}
                  placeholder={`URL do seu perfil no ${platform}`}
                  className="input-modern"
                />
              </div>
            ))}
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="text-lg font-medium text-white flex items-center gap-2">
            <FileText className="w-5 h-5" />
            Termos e Condições
          </h3>
          <label className="flex items-start gap-3 p-4 rounded-lg border border-gray-600 bg-gray-700/50 cursor-pointer hover:border-gray-500 transition-all">
            <input
              type="checkbox"
              checked={formData.termsAccepted || false}
              onChange={(e) => updateFormData('termsAccepted', e.target.checked)}
              className="mt-1 h-4 w-4 text-blue-500 rounded border-gray-500 focus:ring-blue-500"
            />
            <span className="text-sm text-gray-300">
              Entendo e concordo que alterações significativas no escopo acordado podem incorrer em custos adicionais e que o prazo de entrega pode variar conforme a complexidade do projeto
            </span>
          </label>
        </div>

        <div className="space-y-4">
          <label className="block text-sm font-medium text-gray-300">
            Observações Adicionais (Opcional)
          </label>
          <textarea
            value={formData.additionalNotes || ''}
            onChange={(e) => updateFormData('additionalNotes', e.target.value)}
            rows={4}
            placeholder="Algum requisito especial ou dúvida sobre o projeto?"
            className="input-modern"
          />
        </div>

        <div className="pt-6">
          <button
            onClick={onComplete}
            disabled={!formData.termsAccepted}
            className={`w-full py-4 px-6 rounded-lg font-medium
              flex items-center justify-center gap-2
              transition-all duration-300 transform hover:scale-[1.02]
              ${!formData.termsAccepted
                ? 'bg-gray-700 cursor-not-allowed'
                : 'bg-[#2BA6FF] hover:bg-[#1f93eb]'}
              shadow-lg hover:shadow-xl`}
          >
            Revisar e Concluir
          </button>
        </div>
      </div>
    </motion.div>
  );
};