import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { HexColorPicker } from 'react-colorful';
import { Check } from 'lucide-react';

interface Props {
  color: string;
  onChange: (color: string) => void;
  label: string;
}

export const ColorPicker: React.FC<Props> = ({ color, onChange, label }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tempColor, setTempColor] = useState(color);

  const handleColorConfirm = () => {
    onChange(tempColor);
    setIsOpen(false);
  };

  const handleInputChange = (value: string) => {
    setTempColor(value);
    onChange(value);
  };

  return (
    <div className="relative">
      <label className="block text-sm font-medium text-gray-300 mb-2">
        {label}
      </label>
      
      <div className="space-y-2">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full h-20 rounded-lg border-2 border-gray-600 transition-all hover:border-gray-500"
          style={{ backgroundColor: color }}
        />
        
        <div className="flex gap-2">
          <input
            type="text"
            value={color}
            onChange={(e) => handleInputChange(e.target.value)}
            className="flex-1 px-3 py-2 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-white text-sm"
          />
        </div>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="absolute z-10 top-full left-0 mt-2"
          >
            <div className="p-3 bg-gray-800 rounded-lg shadow-xl border border-gray-700">
              <HexColorPicker color={tempColor} onChange={setTempColor} />
              <button
                onClick={handleColorConfirm}
                className="mt-3 w-full py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors flex items-center justify-center gap-2"
              >
                <Check className="w-4 h-4" />
                <span>Confirmar Cor</span>
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};