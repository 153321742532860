import React from 'react';
import { motion } from 'framer-motion';
import { Store, Target, Users, ShoppingBag, Globe2, Link } from 'lucide-react';
import { FormData } from '../../App';

interface Props {
  formData: FormData;
  updateFormData: (field: keyof FormData, value: any) => void;
}

const regions = [
  { id: 'brazil', name: 'Brasil', message: 'Boa sorte!' },
  { id: 'latam', name: 'América Latina', message: '¡Buena suerte!' },
  { id: 'europe', name: 'Europa', message: 'Good luck!' },
  { id: 'other', name: 'Outro', message: 'Good luck!' }
];

const personalities = [
  'Moderna',
  'Leve',
  'Casual',
  'Séria',
  'Pesada',
  'Elegante',
  'Minimalista',
  'Tradicional',
  'Popular',
  'Sofisticada'
];

export const StoreFocus: React.FC<Props> = ({ formData, updateFormData }) => {
  const firstName = formData.fullName.split(' ')[0];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-8"
    >
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-white mb-3">
          {firstName}, vamos entender mais sobre seu negócio!
        </h2>
        <p className="text-gray-400">
          Vamos criar uma loja eficiente que se conecta com seu público-alvo e converte visitantes em vendas.
        </p>
      </div>

      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-4">
            Foco da Loja
          </label>
          <div className="grid grid-cols-2 gap-4">
            {[
              { type: 'Loja Geral', icon: Store },
              { type: 'Loja de Nicho', icon: Target }
            ].map(({ type, icon: Icon }) => (
              <button
                key={type}
                onClick={() => updateFormData('storeFocus', type)}
                className={`p-4 rounded-lg border transition-all ${
                  formData.storeFocus === type
                    ? 'border-blue-500 bg-blue-500/20'
                    : 'border-gray-600 bg-gray-700/50 hover:border-gray-500'
                }`}
              >
                <div className="flex flex-col items-center gap-3">
                  <Icon className="w-6 h-6" />
                  <span>{type}</span>
                </div>
              </button>
            ))}
          </div>
        </div>

        {formData.storeFocus === 'Loja de Nicho' && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Qual é o seu segmento específico de mercado?
            </label>
            <input
              type="text"
              value={formData.nicheMarket}
              onChange={(e) => updateFormData('nicheMarket', e.target.value)}
              className="w-full px-4 py-3 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-white placeholder-gray-400"
              placeholder="Ex: Produtos Pet, Equipamentos de Fitness"
            />
          </motion.div>
        )}

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Possui alguma loja de referência? (Opcional)
          </label>
          <div className="relative">
            <Link className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="url"
              value={formData.referenceStore || ''}
              onChange={(e) => updateFormData('referenceStore', e.target.value)}
              className="w-full pl-10 pr-4 py-3 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-white placeholder-gray-400"
              placeholder="https://exemplo.com"
            />
          </div>
          <p className="mt-2 text-sm text-gray-400">
            Compartilhe o link de uma loja que te inspira
          </p>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Quais produtos você vai vender?
          </label>
          <div className="relative">
            <ShoppingBag className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              value={formData.products || ''}
              onChange={(e) => updateFormData('products', e.target.value)}
              className="w-full pl-10 pr-4 py-3 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-white placeholder-gray-400"
              placeholder="Descreva seus principais produtos"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Quem é seu público-alvo?
          </label>
          <div className="relative">
            <Users className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              value={formData.targetAudience || ''}
              onChange={(e) => updateFormData('targetAudience', e.target.value)}
              className="w-full pl-10 pr-4 py-3 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-white placeholder-gray-400"
              placeholder="Descreva seu público-alvo ideal"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Região Principal de Atuação
          </label>
          <div className="space-y-3">
            <div className="grid grid-cols-2 gap-3">
              {regions.map((region) => (
                <button
                  key={region.id}
                  onClick={() => updateFormData('region', region.id)}
                  className={`p-3 rounded-lg border transition-all flex items-center justify-center gap-2 ${
                    formData.region === region.id
                      ? 'border-blue-500 bg-blue-500/20'
                      : 'border-gray-600 bg-gray-700/50 hover:border-gray-500'
                  }`}
                >
                  <Globe2 className="w-4 h-4" />
                  <span>{region.name}</span>
                </button>
              ))}
            </div>
            
            {formData.region === 'other' && (
              <motion.input
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                type="text"
                value={formData.customRegion || ''}
                onChange={(e) => updateFormData('customRegion', e.target.value)}
                className="w-full px-4 py-3 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-white placeholder-gray-400"
                placeholder="Especifique sua região"
              />
            )}
          </div>
        </div>

        <div className="pt-6 border-t border-gray-700">
          <label className="block text-sm font-medium text-gray-300 mb-4">
            Personalidade da Marca
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {personalities.map((trait) => (
              <button
                key={trait}
                onClick={() => {
                  const current = formData.personality || [];
                  const updated = current.includes(trait)
                    ? current.filter(t => t !== trait)
                    : [...current, trait];
                  updateFormData('personality', updated);
                }}
                className={`p-2 rounded-lg border text-sm transition-all ${
                  formData.personality?.includes(trait)
                    ? 'border-blue-500 bg-blue-500/20 text-white'
                    : 'border-gray-600 bg-gray-700/50 text-gray-300 hover:border-gray-500'
                }`}
              >
                {trait}
              </button>
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
};