import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Plus, 
  Play, 
  Pause, 
  Settings, 
  Trash2, 
  Clock,
  Activity,
  AlertCircle,
  Check,
  X
} from 'lucide-react';
import { automationService } from '../../services/automations';
import { AutomationRule } from '../../services/automations/types';
import { AutomationFlow } from '../automations/AutomationFlow';
import { NewAutomationModal } from '../automations/NewAutomationModal';
import { cn } from '../../lib/utils';

export const AutomationsList: React.FC = () => {
  const [rules, setRules] = useState<AutomationRule[]>(automationService.getRules());
  const [selectedRule, setSelectedRule] = useState<AutomationRule | null>(null);
  const [showNewModal, setShowNewModal] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleToggleRule = (id: string) => {
    automationService.toggleRule(id);
    setRules(automationService.getRules());
  };

  const handleDeleteRule = (id: string) => {
    if (window.confirm('Tem certeza que deseja excluir esta automação?')) {
      automationService.removeRule(id);
      setRules(automationService.getRules());
      if (selectedRule?.id === id) {
        setSelectedRule(null);
      }
    }
  };

  const handleAddRule = (rule: AutomationRule) => {
    try {
      automationService.addRule(rule);
      setRules(automationService.getRules());
      setShowNewModal(false);
      setError(null);
    } catch (err) {
      setError('Erro ao adicionar automação');
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold text-white">Automações</h2>
        <button
          onClick={() => setShowNewModal(true)}
          className="flex items-center gap-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors"
        >
          <Plus className="w-4 h-4" />
          Nova Automação
        </button>
      </div>

      {error && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex items-center gap-2 p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400"
        >
          <AlertCircle className="w-5 h-5" />
          <span>{error}</span>
          <button
            onClick={() => setError(null)}
            className="ml-auto text-red-400 hover:text-red-300"
          >
            <X className="w-4 h-4" />
          </button>
        </motion.div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Rules List */}
        <div className="space-y-4">
          {rules.map((rule) => (
            <motion.div
              key={rule.id}
              onClick={() => setSelectedRule(rule)}
              className={cn(
                "glass-card p-6 cursor-pointer transition-all",
                selectedRule?.id === rule.id
                  ? "ring-2 ring-blue-500"
                  : "hover:bg-gray-800/50"
              )}
            >
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium text-white">{rule.name}</h3>
                <span className={cn(
                  "px-3 py-1 rounded-full text-sm",
                  rule.enabled
                    ? "bg-green-500/20 text-green-300"
                    : "bg-gray-500/20 text-gray-300"
                )}>
                  {rule.enabled ? 'Ativo' : 'Inativo'}
                </span>
              </div>

              <p className="text-gray-400 text-sm mb-4">{rule.description}</p>

              <div className="flex items-center gap-4 text-sm text-gray-400">
                <div className="flex items-center gap-1">
                  <Activity className="w-4 h-4" />
                  Trigger: {rule.trigger}
                </div>
                <div className="flex items-center gap-1">
                  <Clock className="w-4 h-4" />
                  {rule.actions.length} {rule.actions.length === 1 ? 'ação' : 'ações'}
                </div>
              </div>

              <div className="flex items-center gap-2 mt-4">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleToggleRule(rule.id);
                  }}
                  className="p-2 rounded-lg text-gray-300 hover:text-white hover:bg-gray-700/50 transition-colors"
                >
                  {rule.enabled ? (
                    <Pause className="w-4 h-4" />
                  ) : (
                    <Play className="w-4 h-4" />
                  )}
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedRule(rule);
                  }}
                  className="p-2 rounded-lg text-gray-300 hover:text-white hover:bg-gray-700/50 transition-colors"
                >
                  <Settings className="w-4 h-4" />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteRule(rule.id);
                  }}
                  className="p-2 rounded-lg text-gray-300 hover:text-red-400 hover:bg-gray-700/50 transition-colors"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Flow Editor */}
        <div className="glass-card p-6 h-[600px]">
          {selectedRule ? (
            <AutomationFlow rule={selectedRule} />
          ) : (
            <div className="h-full flex items-center justify-center">
              <div className="text-center">
                <Activity className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                <p className="text-gray-400">
                  Selecione uma automação para visualizar seu fluxo
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* New Automation Modal */}
      <AnimatePresence>
        {showNewModal && (
          <NewAutomationModal
            onClose={() => setShowNewModal(false)}
            onSave={handleAddRule}
          />
        )}
      </AnimatePresence>
    </div>
  );
};