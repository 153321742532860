import React from 'react';
import { motion } from 'framer-motion';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { 
  LogOut, 
  LayoutDashboard, 
  FileText, 
  Workflow, 
  Users,
  Factory
} from 'lucide-react';
import { useAuthStore } from '../../stores/authStore';
import { NotificationCenter } from '../notifications/NotificationCenter';

export const DashboardLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const logout = useAuthStore((state) => state.logout);
  const user = useAuthStore((state) => state.user);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const isActive = (path: string) => location.pathname === path;

  return (
    <div className="min-h-screen bg-gradient-animated">
      <nav className="border-b border-gray-700 bg-gray-800/50 backdrop-blur-xl">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <img
                src="https://woopremium.site/wp-content/uploads/2024/11/Logo-Branco.png.webp"
                alt="Logo"
                className="h-8 w-auto"
              />
              <div className="hidden md:block ml-10">
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => navigate('/dashboard')}
                    className={`px-3 py-2 rounded-md text-sm font-medium flex items-center gap-2 transition-colors ${
                      isActive('/dashboard')
                        ? 'bg-blue-500/20 text-white'
                        : 'text-gray-300 hover:text-white hover:bg-gray-700'
                    }`}
                  >
                    <LayoutDashboard className="w-4 h-4" />
                    Dashboard
                  </button>
                  <button
                    onClick={() => navigate('/dashboard/forms')}
                    className={`px-3 py-2 rounded-md text-sm font-medium flex items-center gap-2 transition-colors ${
                      location.pathname.includes('/dashboard/forms')
                        ? 'bg-blue-500/20 text-white'
                        : 'text-gray-300 hover:text-white hover:bg-gray-700'
                    }`}
                  >
                    <FileText className="w-4 h-4" />
                    Formulários
                  </button>
                  <button
                    onClick={() => navigate('/dashboard/production')}
                    className={`px-3 py-2 rounded-md text-sm font-medium flex items-center gap-2 transition-colors ${
                      location.pathname.includes('/dashboard/production')
                        ? 'bg-blue-500/20 text-white'
                        : 'text-gray-300 hover:text-white hover:bg-gray-700'
                    }`}
                  >
                    <Factory className="w-4 h-4" />
                    Produção
                  </button>
                  <button
                    onClick={() => navigate('/dashboard/automations')}
                    className={`px-3 py-2 rounded-md text-sm font-medium flex items-center gap-2 transition-colors ${
                      location.pathname.includes('/dashboard/automations')
                        ? 'bg-blue-500/20 text-white'
                        : 'text-gray-300 hover:text-white hover:bg-gray-700'
                    }`}
                  >
                    <Workflow className="w-4 h-4" />
                    Automações
                  </button>
                  <button
                    onClick={() => navigate('/dashboard/team')}
                    className={`px-3 py-2 rounded-md text-sm font-medium flex items-center gap-2 transition-colors ${
                      location.pathname.includes('/dashboard/team')
                        ? 'bg-blue-500/20 text-white'
                        : 'text-gray-300 hover:text-white hover:bg-gray-700'
                    }`}
                  >
                    <Users className="w-4 h-4" />
                    Equipe
                  </button>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-4">
              <NotificationCenter />
              <span className="text-gray-300 text-sm">Olá, {user}</span>
              <button
                onClick={handleLogout}
                className="flex items-center gap-2 px-4 py-2 rounded-lg text-gray-300 hover:text-white hover:bg-gray-700 transition-colors"
              >
                <LogOut className="w-4 h-4" />
                <span className="text-sm">Sair</span>
              </button>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <Outlet />
      </main>
    </div>
  );
};