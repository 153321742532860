import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Send, Loader, Check, AlertCircle } from 'lucide-react';
import type { FormData } from '../types/form';
import { submitForm } from '../services/forms';
import { useNavigate, useLocation } from 'react-router-dom';

interface Props {
  formData: FormData;
  disabled?: boolean;
  onSuccess?: () => void;
}

export const SubmitButton: React.FC<Props> = ({ formData, disabled, onSuccess }) => {
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [showFeedback, setShowFeedback] = useState(false);
  const [result, setResult] = useState<any>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async () => {
    setStatus('loading');
    setShowFeedback(false);

    try {
      // Determine form ID and plan based on the current route
      let formId = 1; // Default to branding
      let plan = 'branding'; // Default plan
      
      if (location.pathname.includes('/premium')) {
        formId = 2;
        plan = 'premium';
      } else if (location.pathname.includes('/start')) {
        formId = 3;
        plan = 'start';
      }
      
      // Update formData with the plan
      const formDataWithPlan = {
        ...formData,
        plan
      };
      
      const result = await submitForm(formDataWithPlan, formId);
      setResult(result);
      
      if (result.success) {
        setStatus('success');
        setShowFeedback(true);
        onSuccess?.();
        
        // Se foi completamente bem sucedido, redireciona
        if (result.message === 'Formulário enviado com sucesso!') {
          setTimeout(() => {
            navigate(`${location.pathname}/completion`);
          }, 2000);
        } else {
          // Se houve sucesso parcial, mostra a mensagem por mais tempo
          setTimeout(() => {
            setStatus('idle');
            setShowFeedback(false);
          }, 5000);
        }
      } else {
        setStatus('error');
        setShowFeedback(true);
        
        // Reset after 5 seconds if error
        setTimeout(() => {
          setStatus('idle');
          setShowFeedback(false);
        }, 5000);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setStatus('error');
      setShowFeedback(true);
      
      // Reset after 5 seconds if error
      setTimeout(() => {
        setStatus('idle');
        setShowFeedback(false);
      }, 5000);
    }
  };

  return (
    <div className="relative">
      <motion.button
        onClick={handleSubmit}
        disabled={disabled || status === 'loading'}
        className={`
          w-full py-4 px-6 rounded-lg font-medium
          flex items-center justify-center gap-2
          transition-all duration-300 transform hover:scale-[1.02]
          ${status === 'loading' 
            ? 'bg-[#2BA6FF]/50 cursor-wait' 
            : status === 'success'
            ? 'bg-green-500 hover:bg-green-600'
            : status === 'error'
            ? 'bg-red-500 hover:bg-red-600'
            : disabled
            ? 'bg-gray-700 cursor-not-allowed'
            : 'bg-[#2BA6FF] hover:bg-[#1f93eb]'}
          shadow-lg hover:shadow-xl
        `}
      >
        <AnimatePresence mode="wait">
          {status === 'loading' ? (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex items-center gap-2"
            >
              <Loader className="w-5 h-5 animate-spin" />
              <span>Enviando...</span>
            </motion.div>
          ) : status === 'success' ? (
            <motion.div
              key="success"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex items-center gap-2"
            >
              <Check className="w-5 h-5" />
              <span>Enviado com sucesso!</span>
            </motion.div>
          ) : status === 'error' ? (
            <motion.div
              key="error"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex items-center gap-2"
            >
              <AlertCircle className="w-5 h-5" />
              <span>Erro ao enviar</span>
            </motion.div>
          ) : (
            <motion.div
              key="idle"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex items-center gap-2"
            >
              <Send className="w-5 h-5" />
              <span>Enviar Formulário</span>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.button>

      <AnimatePresence>
        {showFeedback && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className={`
              absolute top-full left-0 right-0 mt-4 p-4 rounded-lg
              backdrop-blur-xl border
              ${status === 'success' 
                ? 'bg-green-500/10 border-green-500/20 text-green-300' 
                : 'bg-red-500/10 border-red-500/20 text-red-300'}
            `}
          >
            {status === 'success' ? (
              <p className="text-sm">
                {result?.message || 'Seu formulário foi enviado com sucesso! Redirecionando...'}
              </p>
            ) : (
              <p className="text-sm">
                {result?.message || 'Ocorreu um erro ao enviar o formulário. Por favor, tente novamente.'}
              </p>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};