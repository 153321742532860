import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface TimelineEvent {
  id: string;
  storeId: string;
  storeName: string;
  type: 'status_change' | 'comment' | 'milestone' | 'assignment';
  title: string;
  description: string;
  timestamp: string;
  user: string;
  metadata?: Record<string, any>;
}

interface TimelineStore {
  events: TimelineEvent[];
  addEvent: (event: Omit<TimelineEvent, 'id' | 'timestamp'>) => void;
  getStoreEvents: (storeId: string) => TimelineEvent[];
  removeEvent: (id: string) => void;
}

export const useTimelineStore = create<TimelineStore>()(
  persist(
    (set, get) => ({
      events: [],
      addEvent: (event) => set((state) => ({
        events: [
          {
            ...event,
            id: crypto.randomUUID(),
            timestamp: new Date().toISOString()
          },
          ...state.events
        ]
      })),
      getStoreEvents: (storeId) => {
        return get().events.filter(event => event.storeId === storeId);
      },
      removeEvent: (id) => set((state) => ({
        events: state.events.filter(event => event.id !== id)
      }))
    }),
    {
      name: 'timeline-storage'
    }
  )
);