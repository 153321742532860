import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { X, Plus, Trash2 } from 'lucide-react';
import { AutomationRule, AutomationAction } from '../../services/automations/types';

interface Props {
  onClose: () => void;
  onSave: (rule: AutomationRule) => void;
}

const TRIGGER_TYPES = [
  { value: 'form_submission', label: 'Novo Formulário' },
  { value: 'stage_completion', label: 'Conclusão de Etapa' },
  { value: 'store_completion', label: 'Loja Concluída' },
  { value: 'deadline', label: 'Prazo' }
];

const ACTION_TYPES = [
  { value: 'whatsapp', label: 'Enviar WhatsApp' },
  { value: 'email', label: 'Enviar Email' },
  { value: 'notification', label: 'Criar Notificação' },
  { value: 'timeline', label: 'Adicionar Timeline' }
];

export const NewAutomationModal: React.FC<Props> = ({ onClose, onSave }) => {
  const [rule, setRule] = useState<Partial<AutomationRule>>({
    name: '',
    description: '',
    trigger: 'form_submission',
    actions: [],
    enabled: true
  });

  const handleAddAction = () => {
    setRule(prev => ({
      ...prev,
      actions: [
        ...(prev.actions || []),
        { type: 'notification', config: {} }
      ]
    }));
  };

  const handleRemoveAction = (index: number) => {
    setRule(prev => ({
      ...prev,
      actions: prev.actions?.filter((_, i) => i !== index)
    }));
  };

  const handleUpdateAction = (index: number, action: AutomationAction) => {
    setRule(prev => ({
      ...prev,
      actions: prev.actions?.map((a, i) => i === index ? action : a)
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!rule.name || !rule.trigger || !rule.actions?.length) return;

    onSave({
      id: crypto.randomUUID(),
      name: rule.name,
      description: rule.description || '',
      trigger: rule.trigger as AutomationRule['trigger'],
      actions: rule.actions,
      enabled: true
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50"
    >
      <motion.div
        initial={{ scale: 0.95 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.95 }}
        className="w-full max-w-2xl glass-card p-6"
      >
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-lg font-medium text-white">Nova Automação</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Nome da Automação
            </label>
            <input
              type="text"
              value={rule.name}
              onChange={(e) => setRule({ ...rule, name: e.target.value })}
              className="w-full px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-white"
              placeholder="Ex: Notificação de Novo Formulário"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Descrição
            </label>
            <textarea
              value={rule.description}
              onChange={(e) => setRule({ ...rule, description: e.target.value })}
              className="w-full px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-white"
              placeholder="Descreva o propósito desta automação"
              rows={3}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Trigger
            </label>
            <select
              value={rule.trigger}
              onChange={(e) => setRule({ ...rule, trigger: e.target.value })}
              className="w-full px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-white"
            >
              {TRIGGER_TYPES.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <label className="text-sm font-medium text-gray-300">
                Ações
              </label>
              <button
                type="button"
                onClick={handleAddAction}
                className="flex items-center gap-2 px-3 py-1 text-sm bg-blue-500/20 text-blue-400 rounded-lg hover:bg-blue-500/30 transition-colors"
              >
                <Plus className="w-4 h-4" />
                Adicionar Ação
              </button>
            </div>

            <div className="space-y-4">
              {rule.actions?.map((action, index) => (
                <div
                  key={index}
                  className="flex items-center gap-4 p-4 bg-gray-800/50 rounded-lg"
                >
                  <select
                    value={action.type}
                    onChange={(e) => handleUpdateAction(index, {
                      ...action,
                      type: e.target.value as AutomationAction['type']
                    })}
                    className="flex-1 px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-white"
                  >
                    {ACTION_TYPES.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </select>

                  <button
                    type="button"
                    onClick={() => handleRemoveAction(index)}
                    className="p-2 text-gray-400 hover:text-red-400 hover:bg-gray-700/50 rounded-lg transition-colors"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-300 hover:text-white transition-colors"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors"
            >
              Criar Automação
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};