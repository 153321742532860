import React, { useState, useMemo } from 'react';
import { motion } from 'framer-motion';
import { useQuery } from '@tanstack/react-query';
import { 
  format, 
  subDays, 
  differenceInDays,
  addDays,
  startOfMonth,
  endOfMonth,
  isWithinInterval,
  parseISO
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area
} from 'recharts';
import {
  Calendar,
  Clock,
  AlertTriangle,
  CheckCircle,
  Users,
  TrendingUp,
  Activity,
  AlertOctagon,
  Timer,
  Target,
  BarChart2,
  Store,
  Sun,
  Moon,
  Zap,
  Hourglass,
  Flag
} from 'lucide-react';
import { useProductionStore } from '../../stores/productionStore';
import { cn } from '../../lib/utils';

const COLORS = ['#2BA6FF', '#10B981', '#F59E0B', '#EF4444'];

interface AnimatedCounterProps {
  value: number;
  label: string;
  icon: React.ReactNode;
  trend?: number;
}

const AnimatedCounter: React.FC<AnimatedCounterProps> = ({ value, label, icon, trend }) => {
  const [count, setCount] = useState(0);

  React.useEffect(() => {
    const duration = 1000;
    const steps = 60;
    const increment = value / steps;
    let current = 0;
    const timer = setInterval(() => {
      current += increment;
      if (current >= value) {
        setCount(value);
        clearInterval(timer);
      } else {
        setCount(Math.floor(current));
      }
    }, duration / steps);
    return () => clearInterval(timer);
  }, [value]);

  return (
    <motion.div
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      className="glass-card p-6"
    >
      <div className="flex items-center justify-between mb-4">
        <div className="p-3 rounded-lg bg-blue-500/20">
          {icon}
        </div>
        {trend !== undefined && (
          <div className={cn(
            "flex items-center gap-1 text-sm",
            trend > 0 ? "text-green-400" : "text-red-400"
          )}>
            <TrendingUp className={cn(
              "w-4 h-4",
              trend < 0 && "rotate-180"
            )} />
            <span>{Math.abs(trend)}%</span>
          </div>
        )}
      </div>
      <motion.div
        key={count}
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-3xl font-bold text-white mb-2"
      >
        {count}
      </motion.div>
      <span className="text-gray-400">{label}</span>
    </motion.div>
  );
};

export const DashboardHome: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [dateRange, setDateRange] = useState<[Date, Date]>([subDays(new Date(), 30), new Date()]);
  const { items } = useProductionStore();

  // Calculate statistics
  const stats = useMemo(() => {
    const totalStores = items.length;
    const completedStores = items.filter(item => 
      Object.values(item.status).every(status => status === 'completed')
    ).length;
    const inProgressStores = items.filter(item =>
      Object.values(item.status).some(status => status === 'in_progress')
    ).length;
    const overdueStores = items.filter(item => {
      const deliveryDate = addDays(new Date(item.purchaseDate), 15);
      return differenceInDays(new Date(), deliveryDate) > 0;
    }).length;

    // Calculate average completion time
    const completedItems = items.filter(item => 
      Object.values(item.status).every(status => status === 'completed')
    );
    const avgCompletionTime = completedItems.length > 0
      ? completedItems.reduce((acc, item) => {
          const startDate = new Date(item.purchaseDate);
          const endDate = new Date(item.lastUpdate);
          return acc + differenceInDays(endDate, startDate);
        }, 0) / completedItems.length
      : 0;

    return {
      total: totalStores,
      completed: completedStores,
      inProgress: inProgressStores,
      overdue: overdueStores,
      avgCompletionTime: Math.round(avgCompletionTime)
    };
  }, [items]);

  // Calculate stage completion rates
  const stageStats = useMemo(() => {
    const stages = {
      visualIdentity: 'Identidade Visual',
      banners: 'Banners',
      information: 'Informativos',
      collectionCovers: 'Capas',
      motion: 'Motion',
      socialMedia: 'Social Media'
    };

    return Object.entries(stages).map(([key, label]) => ({
      name: label,
      completed: items.filter(item => item.status[key as keyof typeof item.status] === 'completed').length,
      inProgress: items.filter(item => item.status[key as keyof typeof item.status] === 'in_progress').length,
      pending: items.filter(item => item.status[key as keyof typeof item.status] === 'pending').length
    }));
  }, [items]);

  // Calculate bottlenecks
  const bottlenecks = useMemo(() => {
    const stages = {
      visualIdentity: 'Identidade Visual',
      banners: 'Banners',
      information: 'Informativos',
      collectionCovers: 'Capas',
      motion: 'Motion',
      socialMedia: 'Social Media'
    };

    return Object.entries(stages)
      .map(([key, label]) => ({
        stage: label,
        pending: items.filter(item => item.status[key as keyof typeof item.status] === 'pending').length,
        avgTime: items
          .filter(item => item.status[key as keyof typeof item.status] === 'completed')
          .reduce((acc, item) => acc + differenceInDays(
            new Date(item.lastUpdate),
            new Date(item.purchaseDate)
          ), 0) / items.length || 0
      }))
      .sort((a, b) => b.pending - a.pending);
  }, [items]);

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold text-white">Dashboard</h2>
        <button
          onClick={() => setIsDarkMode(!isDarkMode)}
          className="p-2 rounded-lg bg-gray-800 text-gray-300 hover:text-white transition-colors"
        >
          {isDarkMode ? <Sun className="w-5 h-5" /> : <Moon className="w-5 h-5" />}
        </button>
      </div>

      {/* Main Stats */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <AnimatedCounter
          value={stats.total}
          label="Total de Lojas"
          icon={<Store className="w-6 h-6 text-blue-400" />}
          trend={10}
        />
        <AnimatedCounter
          value={stats.completed}
          label="Lojas Concluídas"
          icon={<CheckCircle className="w-6 h-6 text-green-400" />}
          trend={5}
        />
        <AnimatedCounter
          value={stats.inProgress}
          label="Em Produção"
          icon={<Activity className="w-6 h-6 text-yellow-400" />}
        />
        <AnimatedCounter
          value={stats.avgCompletionTime}
          label="Média de Dias p/ Entrega"
          icon={<Clock className="w-6 h-6 text-purple-400" />}
          trend={-2}
        />
      </div>

      {/* Charts Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Stage Progress */}
        <div className="glass-card p-6">
          <h3 className="text-lg font-medium text-white mb-4">Progresso por Etapa</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={stageStats} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                <XAxis type="number" stroke="#9CA3AF" />
                <YAxis dataKey="name" type="category" stroke="#9CA3AF" />
                <Tooltip
                  contentStyle={{
                    backgroundColor: '#1F2937',
                    border: 'none',
                    borderRadius: '0.5rem',
                    color: '#fff'
                  }}
                />
                <Legend />
                <Bar dataKey="completed" stackId="a" fill="#10B981" name="Concluído" />
                <Bar dataKey="inProgress" stackId="a" fill="#3B82F6" name="Em Andamento" />
                <Bar dataKey="pending" stackId="a" fill="#6B7280" name="Pendente" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Delivery Performance */}
        <div className="glass-card p-6">
          <h3 className="text-lg font-medium text-white mb-4">Performance de Entrega</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={bottlenecks}>
                <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                <XAxis dataKey="stage" stroke="#9CA3AF" />
                <YAxis stroke="#9CA3AF" />
                <Tooltip
                  contentStyle={{
                    backgroundColor: '#1F2937',
                    border: 'none',
                    borderRadius: '0.5rem',
                    color: '#fff'
                  }}
                />
                <Legend />
                <Area 
                  type="monotone" 
                  dataKey="avgTime" 
                  stroke="#3B82F6" 
                  fill="#3B82F6" 
                  fillOpacity={0.2}
                  name="Média de Dias"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Bottlenecks */}
        <div className="glass-card p-6">
          <h3 className="text-lg font-medium text-white mb-4">Gargalos</h3>
          <div className="space-y-4">
            {bottlenecks.slice(0, 3).map((bottleneck, index) => (
              <div key={index} className="flex items-center justify-between p-4 bg-gray-800/50 rounded-lg">
                <div className="flex items-center gap-3">
                  <div className={cn(
                    "p-2 rounded-lg",
                    index === 0 ? "bg-red-500/20" :
                    index === 1 ? "bg-orange-500/20" :
                    "bg-yellow-500/20"
                  )}>
                    <AlertOctagon className={cn(
                      "w-5 h-5",
                      index === 0 ? "text-red-400" :
                      index === 1 ? "text-orange-400" :
                      "text-yellow-400"
                    )} />
                  </div>
                  <div>
                    <h4 className="text-white font-medium">{bottleneck.stage}</h4>
                    <p className="text-sm text-gray-400">
                      {bottleneck.pending} pendentes • {Math.round(bottleneck.avgTime)} dias em média
                    </p>
                  </div>
                </div>
                <div className="text-2xl font-bold text-white">
                  {Math.round((bottleneck.pending / stats.total) * 100)}%
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Team Performance */}
        <div className="glass-card p-6">
          <h3 className="text-lg font-medium text-white mb-4">Performance da Equipe</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={[
                    { name: 'No Prazo', value: stats.completed },
                    { name: 'Em Andamento', value: stats.inProgress },
                    { name: 'Atrasadas', value: stats.overdue }
                  ]}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                  outerRadius={100}
                  fill="#8884d8"
                >
                  <Cell fill="#10B981" />
                  <Cell fill="#3B82F6" />
                  <Cell fill="#EF4444" />
                </Pie>
                <Tooltip
                  contentStyle={{
                    backgroundColor: '#1F2937',
                    border: 'none',
                    borderRadius: '0.5rem',
                    color: '#fff'
                  }}
                />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};