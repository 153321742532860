import React from 'react';
import { motion } from 'framer-motion';

interface Props {
  currentStep: number;
  totalSteps: number;
}

export const ProgressBar: React.FC<Props> = ({ currentStep, totalSteps }) => {
  const progress = (currentStep / totalSteps) * 100;

  return (
    <div className="w-full">
      <div className="h-1 w-full bg-gray-700/30 rounded-full overflow-hidden">
        <motion.div
          className="h-full bg-[#2BA6FF]"
          initial={{ width: 0 }}
          animate={{ width: `${progress}%` }}
          transition={{ duration: 0.3 }}
        />
      </div>
      <div className="mt-2 text-xs md:text-sm text-gray-400 flex justify-between items-center">
        <span>Etapa {currentStep} de {totalSteps}</span>
        <span className="text-[#2BA6FF]">{Math.round(progress)}%</span>
      </div>
    </div>
  );
};