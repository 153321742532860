import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Palette, Wand2, Info, Lightbulb, Target, Loader } from 'lucide-react';
import { FormData } from '../../App';
import { ColorPicker } from '../ColorPicker';
import { StorePreview } from '../StorePreview';
import { generateBrandSuggestion } from '../../services/openai';

interface Props {
  formData: FormData;
  updateFormData: (field: keyof FormData, value: any) => void;
}

interface ColorAnalysis {
  primary: string;
  accent: string;
  analysis: string;
}

export const BrandColors: React.FC<Props> = ({ formData, updateFormData }) => {
  const [currentAnalysis, setCurrentAnalysis] = useState<string | null>(null);
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showThirdColor, setShowThirdColor] = useState(false);

  const updateColor = (type: 'primary' | 'secondary' | 'accent' | 'tertiary', color: string) => {
    const newColors = { ...formData.colors, [type]: color };
    updateFormData('colors', newColors);
  };

  const generateNewPalette = async () => {
    setIsGenerating(true);
    try {
      const suggestion = await generateBrandSuggestion('colors', {
        nicheMarket: formData.nicheMarket || '',
        targetAudience: formData.targetAudience || '',
        storeFocus: formData.storeFocus,
        personality: formData.personality
      });
      
      updateFormData('colors', {
        ...formData.colors,
        primary: suggestion.primary,
        accent: suggestion.accent
      });
      
      setCurrentAnalysis(suggestion.analysis);
      setShowAnalysis(true);
    } catch (error) {
      console.error('Error generating colors:', error);
    }
    setIsGenerating(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-8"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <Palette className="w-6 h-6 text-blue-500" />
          <h2 className="text-2xl font-bold text-white">Cores da Marca</h2>
        </div>
        <button
          onClick={generateNewPalette}
          disabled={isGenerating}
          className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
            isGenerating
              ? 'bg-gray-700 text-gray-400 cursor-not-allowed'
              : 'bg-blue-500/20 text-blue-400 hover:bg-blue-500/30'
          }`}
        >
          {isGenerating ? (
            <>
              <Loader className="w-4 h-4 animate-spin" />
              Gerando...
            </>
          ) : (
            <>
              <Wand2 className="w-4 h-4" />
              Nova Sugestão
            </>
          )}
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="space-y-6">
          <ColorPicker
            label="Cor Primária"
            color={formData.colors.primary}
            onChange={(color) => updateColor('primary', color)}
          />
          <ColorPicker
            label="Cor de Destaque"
            color={formData.colors.accent}
            onChange={(color) => updateColor('accent', color)}
          />
          
          <div className="flex items-center justify-between">
            <button
              onClick={() => setShowThirdColor(!showThirdColor)}
              className="text-blue-400 hover:text-blue-300 transition-colors text-sm flex items-center gap-2"
            >
              {showThirdColor ? 'Remover terceira cor' : 'Adicionar terceira cor'}
            </button>
          </div>

          {showThirdColor && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
            >
              <ColorPicker
                label="Cor Terciária"
                color={formData.colors.tertiary || '#808080'}
                onChange={(color) => updateColor('tertiary', color)}
              />
            </motion.div>
          )}

          <div className="p-4 bg-gray-800/50 rounded-lg">
            <div className="flex items-center gap-2 mb-2">
              <Info className="w-4 h-4 text-blue-400" />
              <span className="text-sm text-gray-300">Fundo sempre branco</span>
            </div>
            <p className="text-xs text-gray-400">
              Mantemos o fundo branco para garantir a melhor visibilidade dos produtos e uma experiência de compra agradável.
            </p>
          </div>
        </div>

        <div className="space-y-6">
          <StorePreview
            storeName={formData.storeName}
            colors={formData.colors}
          />

          <AnimatePresence>
            {showAnalysis && currentAnalysis && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                className="p-4 bg-gray-800/50 rounded-lg space-y-4"
              >
                <div className="flex items-center gap-2">
                  <Wand2 className="w-4 h-4 text-blue-400" />
                  <h3 className="font-medium text-white">Análise da Vision AI</h3>
                </div>
                
                <div className="prose prose-sm prose-invert">
                  {currentAnalysis.split('\n').map((paragraph, index) => (
                    <p key={index} className="text-sm text-gray-400">
                      {paragraph}
                    </p>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </motion.div>
  );
};