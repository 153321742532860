import React from 'react';
import { motion } from 'framer-motion';
import { 
  Users,
  Trophy,
  Star,
  Target,
  TrendingUp,
  Clock,
  Award,
  Crown
} from 'lucide-react';
import { useTeamStore } from '../../stores/teamStore';
import { useAchievementStore } from '../../stores/achievementStore';
import { TeamRanking } from './TeamRanking';
import { RankBadge } from './RankBadge';
import { InitialsAvatar } from './InitialsAvatar';
import { cn } from '../../lib/utils';

export const TeamOverview: React.FC = () => {
  const { members } = useTeamStore();
  const { getMemberProgress } = useAchievementStore();

  // Calculate team stats
  const teamStats = members.reduce((acc, member) => {
    const progress = getMemberProgress(member.id);
    if (progress) {
      acc.totalXP += progress.xp;
      acc.tasksCompleted += progress.stats.tasksCompleted;
      acc.averageFeedback += progress.stats.feedbackScore;
      if (progress.stats.currentStreak > acc.bestStreak) {
        acc.bestStreak = progress.stats.currentStreak;
      }
    }
    return acc;
  }, {
    totalXP: 0,
    tasksCompleted: 0,
    averageFeedback: 0,
    bestStreak: 0
  });

  teamStats.averageFeedback = members.length > 0
    ? teamStats.averageFeedback / members.length
    : 0;

  // Get top performers
  const topPerformers = [...members]
    .map(member => ({
      ...member,
      progress: getMemberProgress(member.id)
    }))
    .sort((a, b) => (b.progress?.xp || 0) - (a.progress?.xp || 0))
    .slice(0, 3);

  return (
    <div className="space-y-8">
      {/* Team Stats */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="glass-card p-6"
        >
          <div className="flex items-center gap-3">
            <div className="p-3 rounded-lg bg-blue-500/20">
              <Trophy className="w-6 h-6 text-blue-400" />
            </div>
            <div>
              <h3 className="text-lg font-medium text-white">XP Total</h3>
              <p className="text-2xl font-bold text-blue-400">
                {teamStats.totalXP.toLocaleString()}
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="glass-card p-6"
        >
          <div className="flex items-center gap-3">
            <div className="p-3 rounded-lg bg-green-500/20">
              <Target className="w-6 h-6 text-green-400" />
            </div>
            <div>
              <h3 className="text-lg font-medium text-white">Tarefas</h3>
              <p className="text-2xl font-bold text-green-400">
                {teamStats.tasksCompleted}
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="glass-card p-6"
        >
          <div className="flex items-center gap-3">
            <div className="p-3 rounded-lg bg-yellow-500/20">
              <Star className="w-6 h-6 text-yellow-400" />
            </div>
            <div>
              <h3 className="text-lg font-medium text-white">Avaliação</h3>
              <p className="text-2xl font-bold text-yellow-400">
                {teamStats.averageFeedback.toFixed(1)}
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="glass-card p-6"
        >
          <div className="flex items-center gap-3">
            <div className="p-3 rounded-lg bg-purple-500/20">
              <Clock className="w-6 h-6 text-purple-400" />
            </div>
            <div>
              <h3 className="text-lg font-medium text-white">Melhor Sequência</h3>
              <p className="text-2xl font-bold text-purple-400">
                {teamStats.bestStreak} dias
              </p>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Top Performers */}
      <div className="glass-card p-6">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-lg font-medium text-white flex items-center gap-2">
            <Crown className="w-5 h-5 text-yellow-400" />
            Top Performers
          </h3>
          <div className="flex items-center gap-2">
            <TrendingUp className="w-4 h-4 text-green-400" />
            <span className="text-sm text-gray-400">Últimos 30 dias</span>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {topPerformers.map((member, index) => (
            <motion.div
              key={member.id}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className={cn(
                "p-4 rounded-lg",
                index === 0 ? "bg-yellow-500/20" :
                index === 1 ? "bg-gray-500/20" :
                "bg-yellow-600/20"
              )}
            >
              <div className="flex items-center gap-4">
                <InitialsAvatar
                  name={member.name}
                  rank={member.rank}
                  size="md"
                />
                <div>
                  <h4 className="text-white font-medium">{member.name}</h4>
                  <div className="flex items-center gap-2 mt-1">
                    <RankBadge rank={member.rank} size="sm" />
                    <span className="text-sm text-gray-400">
                      {member.progress?.xp.toLocaleString()} XP
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-4 grid grid-cols-2 gap-2">
                <div className="p-2 rounded-lg bg-gray-800/50">
                  <div className="text-sm text-gray-400">Tarefas</div>
                  <div className="text-lg font-medium text-white">
                    {member.progress?.stats.tasksCompleted || 0}
                  </div>
                </div>
                <div className="p-2 rounded-lg bg-gray-800/50">
                  <div className="text-sm text-gray-400">Avaliação</div>
                  <div className="text-lg font-medium text-white">
                    {member.progress?.stats.feedbackScore.toFixed(1) || 0}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Team Ranking */}
      <TeamRanking />
    </div>
  );
};