import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FileText, Edit2, Eye, ExternalLink, Bell, Search } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getFormResponses } from '../../services/database';

export const FormList: React.FC = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  const { data: brandingResponses } = useQuery({
    queryKey: ['formResponses', 1],
    queryFn: () => getFormResponses(1),
    refetchInterval: 30000,
  });

  const { data: premiumResponses } = useQuery({
    queryKey: ['formResponses', 2],
    queryFn: () => getFormResponses(2),
    refetchInterval: 30000,
  });

  const { data: startResponses } = useQuery({
    queryKey: ['formResponses', 3],
    queryFn: () => getFormResponses(3),
    refetchInterval: 30000,
  });

  const getTodayResponses = (responses: any[]) => {
    if (!responses) return 0;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    return responses.filter(response => {
      const responseDate = new Date(response.created_at);
      responseDate.setHours(0, 0, 0, 0);
      return responseDate.getTime() === today.getTime();
    }).length;
  };

  const forms = [
    {
      id: 1,
      name: 'Plano Branding',
      responses: brandingResponses?.length || 0,
      todayResponses: getTodayResponses(brandingResponses || []),
      url: '/form'
    },
    {
      id: 2,
      name: 'Plano Premium',
      responses: premiumResponses?.length || 0,
      todayResponses: getTodayResponses(premiumResponses || []),
      url: '/form/premium'
    },
    {
      id: 3,
      name: 'Plano Start',
      responses: startResponses?.length || 0,
      todayResponses: getTodayResponses(startResponses || []),
      url: '/form/start'
    }
  ];

  const filteredForms = forms.filter(form => 
    form.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold text-white">Formulários</h2>
      </div>

      <div className="relative">
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Buscar formulário..."
          className="w-full pl-10 pr-4 py-3 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-white placeholder-gray-400"
        />
      </div>

      <div className="grid gap-6">
        {filteredForms.map((form) => (
          <div
            key={form.id}
            className="glass-card p-6 rounded-lg space-y-4"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <FileText className="w-6 h-6 text-blue-400" />
                <h3 className="text-lg font-medium text-white">
                  {form.name}
                </h3>
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => navigate(form.url)}
                  className="flex items-center gap-2 px-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white transition-colors"
                >
                  <ExternalLink className="w-4 h-4" />
                  <span>Ver Formulário</span>
                </button>
                <button
                  onClick={() => navigate(`/dashboard/forms/${form.id}/edit`)}
                  className="p-2 rounded-lg text-gray-300 hover:text-white hover:bg-gray-700 transition-colors"
                  title="Editar"
                >
                  <Edit2 className="w-4 h-4" />
                </button>
                <button
                  onClick={() => navigate(`/dashboard/forms/${form.id}/responses`)}
                  className="p-2 rounded-lg text-gray-300 hover:text-white hover:bg-gray-700 transition-colors"
                  title="Ver Respostas"
                >
                  <Eye className="w-4 h-4" />
                </button>
                {form.todayResponses > 0 && (
                  <div className="relative">
                    <Bell className="w-4 h-4 text-blue-400" />
                    <span className="absolute -top-1 -right-1 w-4 h-4 text-xs flex items-center justify-center bg-blue-500 text-white rounded-full">
                      {form.todayResponses}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="text-sm text-gray-400">
              {form.responses} {form.responses === 1 ? 'resposta' : 'respostas'}
            </div>
          </div>
        ))}
      </div>
    </motion.div>
  );
};