import { create } from 'zustand';
import { supabase } from '../lib/supabase';
import { updateStoreStatus } from '../services/production';

export interface ProductionStatus {
  visualIdentity: 'pending' | 'in_progress' | 'completed';
  banners: 'pending' | 'in_progress' | 'completed';
  information: 'pending' | 'in_progress' | 'completed';
  collectionCovers: 'pending' | 'in_progress' | 'completed';
  socialMedia: 'pending' | 'in_progress' | 'completed';
  motion: 'pending' | 'in_progress' | 'completed';
  checkout: 'pending' | 'in_progress' | 'completed';
  refinement: 'pending' | 'in_progress' | 'completed';
  storeConfig: 'pending' | 'in_progress' | 'completed';
}

export interface ProductionItem {
  id: string;
  storeName: string;
  storeType: 'generic' | 'niche';
  storeCategory?: string;
  platform?: 'shopify' | 'nuvemshop' | 'yampi' | 'vtex' | 'woocommerce';
  plan: 'start' | 'premium' | 'branding';
  status: ProductionStatus;
  assignedTo: string;
  driveLink?: string;
  purchaseDate: string;
  completedStoreLink?: string;
  lastUpdate: string;
  revisions: number;
  tags: string[];
  priority?: 'low' | 'medium' | 'high' | 'urgent';
  client: {
    name: string;
    email: string;
    phone: string;
  };
  paymentStatus?: 'pending' | 'partial' | 'completed';
  paymentValue?: number;
  paymentMethod?: string;
  customRequirements?: string;
  responsibleDesigner?: string;
  responsibleDeveloper?: string;
  expectedDeliveryDate?: string;
  integrations?: string[];
}

interface ProductionStore {
  items: ProductionItem[];
  loading: boolean;
  error: string | null;
  initialized: boolean;
  fetchItems: () => Promise<void>;
  addItem: (item: ProductionItem) => void;
  setItems: (items: ProductionItem[]) => void;
  updateStatus: (id: string, status: Partial<ProductionStatus>) => Promise<void>;
  updateItem: (id: string, updates: Partial<ProductionItem>) => Promise<void>;
  removeItem: (id: string) => void;
}

const initialStatus: ProductionStatus = {
  visualIdentity: 'pending',
  banners: 'pending',
  information: 'pending',
  collectionCovers: 'pending',
  socialMedia: 'pending',
  motion: 'pending',
  checkout: 'pending',
  refinement: 'pending',
  storeConfig: 'pending'
};

// Helper function to map database fields to store fields
const mapDatabaseToStore = (dbItem: any): ProductionItem => ({
  id: dbItem.id,
  storeName: dbItem.store_name,
  storeType: dbItem.store_type,
  storeCategory: dbItem.store_category,
  platform: dbItem.platform,
  plan: dbItem.plan,
  status: dbItem.status,
  assignedTo: dbItem.assigned_to,
  driveLink: dbItem.drive_link,
  purchaseDate: dbItem.purchase_date,
  completedStoreLink: dbItem.completed_store_link,
  lastUpdate: dbItem.updated_at,
  revisions: dbItem.revisions || 0,
  tags: dbItem.tags || [],
  priority: dbItem.priority,
  client: dbItem.client,
  paymentStatus: dbItem.payment_status,
  paymentValue: dbItem.payment_value,
  paymentMethod: dbItem.payment_method,
  customRequirements: dbItem.custom_requirements,
  responsibleDesigner: dbItem.responsible_designer,
  responsibleDeveloper: dbItem.responsible_developer,
  expectedDeliveryDate: dbItem.expected_delivery_date,
  integrations: dbItem.integrations
});

export const useProductionStore = create<ProductionStore>()((set, get) => ({
  items: [],
  loading: false,
  error: null,
  initialized: false,

  fetchItems: async () => {
    set({ loading: true, error: null });
    try {
      const { data, error } = await supabase
        .from('store_production')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;

      const mappedItems = data?.map(mapDatabaseToStore) || [];
      set({ items: mappedItems, initialized: true });
    } catch (error) {
      console.error('Error fetching stores:', error);
      set({ error: 'Erro ao carregar lojas' });
    } finally {
      set({ loading: false });
    }
  },

  addItem: (item) => {
    set((state) => ({
      items: [item, ...state.items]
    }));
  },

  setItems: (items: ProductionItem[]) => {
    set({
      items,
      initialized: true
    });
  },

  updateStatus: async (id, status) => {
    const currentItems = get().items;
    const item = currentItems.find(item => item.id === id);
    
    if (item) {
      const newStatus = { ...item.status, ...status };
      const now = new Date().toISOString();

      try {
        // Verificar se há uma sessão ativa
        const { data: session } = await supabase.auth.getSession();
        if (!session) {
          throw new Error('Usuário não autenticado');
        }

        // Update Supabase usando jsonb_set para atualizar apenas o campo específico
        const statusKey = Object.keys(status)[0];
        const statusValue = Object.values(status)[0];
        
        const { error: storeError } = await supabase
          .from('store_production')
          .update({ 
            status: newStatus, // Enviar o objeto completo de status
            last_update: now,
            updated_at: now
          })
          .eq('id', id)
          .select()
          .single();

        if (storeError) {
          console.error('Erro ao atualizar status:', storeError);
          throw storeError;
        }

        // Add to timeline
        const { error: timelineError } = await supabase
          .from('store_timeline')
          .insert([{
            store_id: id,
            event_type: 'status_change',
            title: 'Status Atualizado',
            description: `Status de ${statusKey} alterado para ${statusValue}`,
            metadata: {
              field: statusKey,
              newStatus: statusValue,
              previousStatus: item.status[statusKey]
            },
            created_by: session?.session?.user?.email || 'Sistema'
          }]);

        if (timelineError) {
          console.error('Erro ao adicionar timeline:', timelineError);
          throw timelineError;
        }

        // If database updates successful, update local state
        set((state) => ({
          items: state.items.map(item =>
            item.id === id
              ? { 
                  ...item, 
                  status: newStatus,
                  lastUpdate: now
                }
              : item
          )
        }));

      } catch (error) {
        console.error('Erro ao atualizar status da loja:', error);
        throw error;
      }
    }
  },

  updateItem: async (id, updates) => {
    try {
      // Primeiro atualiza no Supabase
      await updateStoreStatus(id, updates);

      // Se a atualização no Supabase foi bem sucedida, atualiza o estado local
      set((state) => ({
        items: state.items.map((item) =>
          item.id === id ? { ...item, ...updates } : item
        ),
      }));
    } catch (error) {
      console.error('Erro ao atualizar item:', error);
      throw error;
    }
  },

  removeItem: (id) => {
    set((state) => ({
      items: state.items.filter(item => item.id !== id)
    }));
  }
}));