import React, { useEffect } from 'react';
import { useNotificationStore } from '../../stores/notificationStore';

// Som de notificação em base64
const NOTIFICATION_SOUND = 'data:audio/mp3;base64,...'; // Som curto e sutil

export const NotificationSound: React.FC = () => {
  const { notifications } = useNotificationStore();
  const audio = new Audio(NOTIFICATION_SOUND);

  useEffect(() => {
    const unreadNotifications = notifications.filter(n => !n.read);
    if (unreadNotifications.length > 0) {
      audio.play().catch(console.error);
    }
  }, [notifications]);

  return null;
};