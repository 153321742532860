import { supabase } from '../../lib/supabase';
import type { FormData } from '../../types/form';

export const saveFormResponse = async (formData: FormData, formId: number = 1) => {
  try {
    const insertData = {
      form_id: formId,
      response_data: formData,
      status: 'new'
    };

    // Try to get the authenticated session
    const { data: sessionData } = await supabase.auth.getSession();
    if (sessionData?.session?.user?.id) {
      Object.assign(insertData, { user_id: sessionData.session.user.id });
    }
    
    // Removido o .select() e .single() para evitar problemas de autenticação
    const { error } = await supabase
      .from('form_responses')
      .insert([insertData]);

    if (error) {
      console.error('Error saving form response:', error);
      if (error.code === '23505') { // Unique violation
        throw new Error('Este formulário já foi enviado anteriormente.');
      } else {
        throw new Error('Erro ao salvar formulário. Por favor, tente novamente mais tarde.');
      }
    }

    return true; // Retorna true se a inserção foi bem sucedida
  } catch (error) {
    console.error('Error in saveFormResponse:', error);
    if (error instanceof Error) {
      throw error;
    }
    throw new Error('Erro inesperado ao salvar formulário.');
  }
};

export const getFormResponses = async (formId: number) => {
  console.log('Iniciando getFormResponses com formId:', formId);
  
  // Verificar sessão do usuário
  const { data: session } = await supabase.auth.getSession();
  console.log('Sessão do usuário:', session?.data?.session?.user);
  
  try {
    console.log('Fazendo requisição para form_responses...');
    const { data, error } = await supabase
      .from('form_responses')
      .select('*')
      .eq('form_id', formId)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Erro ao buscar respostas:', error);
      throw error;
    }

    console.log(`Respostas encontradas: ${data?.length || 0}`);
    return data || [];
  } catch (error) {
    console.error('Erro em getFormResponses:', error);
    throw error;
  }
};

export const updateFormStatus = async (id: string, status: string) => {
  try {
    const { data, error } = await supabase
      .from('form_responses')
      .update({ status })
      .eq('id', id)
      .select()
      .single();

    if (error) {
      console.error('Error updating form status:', error);
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error in updateFormStatus:', error);
    throw error;
  }
};