import { create } from 'zustand';
import { supabase } from '../lib/supabase';
import type { TeamMember, Role, Permission } from '../types/team';

interface TeamStore {
  members: TeamMember[];
  isLoading: boolean;
  error: string | null;
  fetchMembers: () => Promise<void>;
  addMember: (member: Omit<TeamMember, 'id' | 'created_at' | 'updated_at'>) => Promise<void>;
  updateMember: (id: string, updates: Partial<TeamMember>) => Promise<void>;
  removeMember: (id: string) => Promise<void>;
  updateMemberPermissions: (memberId: string, resource: string, permissions: Permission[]) => Promise<void>;
}

export const useTeamStore = create<TeamStore>((set, get) => ({
  members: [],
  isLoading: false,
  error: null,

  fetchMembers: async () => {
    set({ isLoading: true, error: null });
    try {
      const { data: members, error } = await supabase
        .from('team_members')
        .select(`
          *,
          member_permissions (*)
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;

      // Transform the data to match our TeamMember type
      const transformedMembers = members.map(member => ({
        id: member.id,
        name: member.name,
        email: member.email,
        role: member.role as Role,
        status: member.status,
        rank: member.rank,
        pageAccess: member.member_permissions.map(permission => ({
          resource: permission.resource,
          can_view: permission.can_view,
          can_edit: permission.can_edit,
          can_delete: permission.can_delete,
          can_manage: permission.can_manage
        }))
      }));

      set({ members: transformedMembers, isLoading: false });
    } catch (error) {
      set({ error: 'Erro ao carregar membros', isLoading: false });
      console.error('Error fetching members:', error);
    }
  },

  addMember: async (member) => {
    set({ isLoading: true, error: null });
    try {
      // Primeiro, vamos validar os dados
      if (!member.name || !member.email || !member.role || !member.status) {
        throw new Error('Dados inválidos');
      }

      // Verificar se o usuário está autenticado
      const session = await supabase.auth.getSession();
      if (!session.data.session?.access_token) {
        throw new Error('Você precisa estar autenticado');
      }

      // Insert member
      const { data: newMember, error: memberError } = await supabase
        .from('team_members')
        .insert({
          name: member.name,
          email: member.email,
          user_role: member.role,
          user_status: member.status,
          user_rank: member.rank || 'ferro'
        })
        .select('*')
        .single();

      if (memberError) {
        console.error('Member error:', memberError);
        throw new Error(memberError.message);
      }
      
      if (!newMember) throw new Error('Erro ao criar membro');

      // Insert permissions
      if (member.pageAccess && member.pageAccess.length > 0) {
        const permissionPromises = member.pageAccess.map(access => 
          supabase
            .from('member_permissions')
            .insert({
              member_id: newMember.id,
              resource: access.resource,
              can_view: access.can_view,
              can_edit: access.can_edit,
              can_delete: access.can_delete,
              can_manage: access.can_manage
            })
        );

        const permissionResults = await Promise.all(permissionPromises);
        const permissionErrors = permissionResults.filter(result => result.error);
        
        if (permissionErrors.length > 0) {
          console.error('Permission errors:', permissionErrors);
          throw new Error('Erro ao definir permissões');
        }
      }

      // Refresh members list
      await get().fetchMembers();
      set({ isLoading: false });
    } catch (error) {
      console.error('Error adding member:', error);
      set({ 
        error: error instanceof Error ? error.message : 'Erro ao adicionar membro', 
        isLoading: false 
      });
      throw error;
    }
  },

  updateMember: async (id, updates) => {
    set({ isLoading: true, error: null });
    try {
      const { error: memberError } = await supabase
        .from('team_members')
        .update({
          name: updates.name,
          email: updates.email,
          role: updates.role,
          status: updates.status,
          rank: updates.rank
        })
        .eq('id', id);

      if (memberError) throw memberError;

      if (updates.pageAccess) {
        // Update permissions
        const { error: deleteError } = await supabase
          .from('member_permissions')
          .delete()
          .eq('member_id', id);

        if (deleteError) throw deleteError;

        const permissionPromises = updates.pageAccess.map(access => 
          supabase
            .from('member_permissions')
            .insert({
              member_id: id,
              resource: access.resource,
              can_view: access.permissions.includes('visualizar'),
              can_edit: access.permissions.includes('editar'),
              can_delete: access.permissions.includes('excluir'),
              can_manage: access.permissions.includes('gerenciar')
            })
        );

        await Promise.all(permissionPromises);
      }

      // Refresh members list
      await get().fetchMembers();
      set({ isLoading: false });
    } catch (error) {
      set({ error: 'Erro ao atualizar membro', isLoading: false });
      console.error('Error updating member:', error);
    }
  },

  removeMember: async (id) => {
    set({ isLoading: true, error: null });
    try {
      const { error } = await supabase
        .from('team_members')
        .delete()
        .eq('id', id);

      if (error) throw error;

      // Refresh members list
      await get().fetchMembers();
      set({ isLoading: false });
    } catch (error) {
      set({ error: 'Erro ao remover membro', isLoading: false });
      console.error('Error removing member:', error);
    }
  },

  updateMemberPermissions: async (memberId, resource, permissions) => {
    set({ isLoading: true, error: null });
    try {
      const { error } = await supabase
        .from('member_permissions')
        .upsert({
          member_id: memberId,
          resource,
          can_view: permissions.includes('visualizar'),
          can_edit: permissions.includes('editar'),
          can_delete: permissions.includes('excluir'),
          can_manage: permissions.includes('gerenciar')
        });

      if (error) throw error;

      // Refresh members list
      await get().fetchMembers();
      set({ isLoading: false });
    } catch (error) {
      set({ error: 'Erro ao atualizar permissões', isLoading: false });
      console.error('Error updating permissions:', error);
    }
  }
}));