import React from 'react';
import { motion } from 'framer-motion';
import { ShoppingBag, Search, Heart, Menu, Star } from 'lucide-react';

interface Props {
  storeName: string;
  colors: {
    primary: string;
    secondary: string;
    accent: string;
    tertiary?: string;
  };
}

export const StorePreview: React.FC<Props> = ({ storeName, colors }) => {
  const products = [
    { name: 'Produto 1', price: 'R$ 199,90', rating: 4.5 },
    { name: 'Produto 2', price: 'R$ 299,90', rating: 5.0 },
    { name: 'Produto 3', price: 'R$ 149,90', rating: 4.8 },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="w-full max-w-md mx-auto rounded-xl overflow-hidden shadow-lg"
      style={{ backgroundColor: colors.secondary }}
    >
      <header
        className="p-3 flex items-center justify-between"
        style={{ backgroundColor: colors.primary }}
      >
        <div className="flex items-center gap-4">
          <Menu className="w-5 h-5" style={{ color: colors.secondary }} />
          <h1 className="text-base font-medium" style={{ color: colors.secondary }}>
            {storeName || 'Sua Loja Online'}
          </h1>
        </div>
        <div className="flex items-center gap-3">
          <Search className="w-4 h-4" style={{ color: colors.secondary }} />
          <Heart className="w-4 h-4" style={{ color: colors.tertiary || colors.secondary }} />
          <div className="relative">
            <ShoppingBag className="w-4 h-4" style={{ color: colors.secondary }} />
            <span
              className="absolute -top-1 -right-1 w-3 h-3 text-[10px] flex items-center justify-center rounded-full"
              style={{ backgroundColor: colors.accent, color: colors.secondary }}
            >
              2
            </span>
          </div>
        </div>
      </header>

      <main className="p-3 space-y-3">
        <div
          className="w-full h-24 rounded-lg flex items-center justify-center"
          style={{ backgroundColor: colors.tertiary ? `${colors.tertiary}10` : `${colors.primary}10` }}
        >
          <span className="text-sm" style={{ color: colors.primary }}>Banner Promocional</span>
        </div>

        <div className="grid grid-cols-3 gap-2">
          {products.map((product, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.02 }}
              className="rounded-lg overflow-hidden"
            >
              <div
                className="w-full h-20 mb-2"
                style={{ backgroundColor: colors.primary + '15' }}
              />
              <div className="px-2 pb-2 space-y-1">
                <div className="flex items-center gap-1">
                  <Star className="w-3 h-3" style={{ color: colors.accent }} />
                  <span className="text-[10px]" style={{ color: colors.primary }}>
                    {product.rating}
                  </span>
                </div>
                <div
                  className="text-xs font-medium truncate"
                  style={{ color: colors.primary }}
                >
                  {product.name}
                </div>
                <div
                  className="text-xs font-bold"
                  style={{ color: colors.tertiary || colors.accent }}
                >
                  {product.price}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </main>

      <footer
        className="p-2 mt-2 text-[10px] text-center"
        style={{ color: colors.primary + '80' }}
      >
        © 2024 {storeName || 'Sua Loja Online'}. Todos os direitos reservados.
      </footer>
    </motion.div>
  );
};