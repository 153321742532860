import React from 'react';
import { motion } from 'framer-motion';
import { useTeamStore } from '../../stores/teamStore';
import { InitialsAvatar } from './InitialsAvatar';
import { RankBadge } from './RankBadge';
import { cn } from '../../lib/utils';
import type { TeamMember } from '../../types/team';

interface Props {
  onEdit: (member: TeamMember) => void;
}

export const MemberList: React.FC<Props> = ({ onEdit }) => {
  const { members } = useTeamStore();

  return (
    <div className="space-y-4">
      {members.map((member) => (
        <motion.div
          key={member.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="glass-card p-4"
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <InitialsAvatar
                name={member.name}
                rank={member.rank}
                size="md"
                className="flex-shrink-0"
              />
              
              <div>
                <h3 className="text-lg font-medium text-white">{member.name}</h3>
                <div className="flex items-center gap-2 mt-1">
                  <span className={cn(
                    "px-2 py-0.5 rounded-full text-xs",
                    member.status === 'active' && "bg-green-500/20 text-green-300",
                    member.status === 'pending' && "bg-yellow-500/20 text-yellow-300",
                    member.status === 'inactive' && "bg-red-500/20 text-red-300"
                  )}>
                    {member.status === 'active' ? 'Ativo' :
                     member.status === 'pending' ? 'Pendente' : 'Inativo'}
                  </span>
                  <span className="text-sm text-gray-400">
                    {member.role === 'designer' ? 'Designer' :
                     member.role === 'motion_designer' ? 'Motion Designer' :
                     member.role === 'support' ? 'Suporte' :
                     member.role === 'manager' ? 'Gerente' :
                     member.role === 'admin' ? 'Administrador' :
                     member.role === 'customer_service' ? 'Atendimento' :
                     'Produção'}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-4">
              <RankBadge rank={member.rank} size="sm" />
              <button
                onClick={() => onEdit(member)}
                className="px-4 py-2 rounded-lg bg-blue-500/20 text-blue-300 hover:bg-blue-500/30 transition-colors"
              >
                Editar
              </button>
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};