import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { X, Save, Users, Mail, Shield, Layout, AlertCircle, Trash2 } from 'lucide-react';
import { useTeamStore } from '../../stores/teamStore';
import { PhotoUpload } from './PhotoUpload';
import { RankBadge } from './RankBadge';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { cn } from '../../lib/utils';
import type { TeamMember, Role, Permission, Rank } from '../../types/team';

interface Props {
  initialData?: TeamMember;
  onClose: () => void;
}

const ROLES: { value: Role; label: string }[] = [
  { value: 'designer', label: 'Designer' },
  { value: 'motion_designer', label: 'Motion Designer' },
  { value: 'support', label: 'Suporte' },
  { value: 'manager', label: 'Gerente' },
  { value: 'admin', label: 'Administrador' },
  { value: 'customer_service', label: 'Atendimento' },
  { value: 'production', label: 'Produção' }
];

const AVAILABLE_PAGES = [
  { path: '/dashboard', name: 'Dashboard' },
  { path: '/dashboard/forms', name: 'Formulários' },
  { path: '/dashboard/production', name: 'Produção' },
  { path: '/dashboard/automations', name: 'Automações' },
  { path: '/dashboard/team', name: 'Equipe' }
];

const PERMISSIONS: { value: Permission; label: string }[] = [
  { value: 'visualizar', label: 'Visualizar' },
  { value: 'editar', label: 'Editar' },
  { value: 'excluir', label: 'Excluir' },
  { value: 'gerenciar', label: 'Gerenciar' }
];

const RANKS: { value: Rank; label: string }[] = [
  { value: 'ferro', label: 'Ferro' },
  { value: 'prata', label: 'Prata' },
  { value: 'ouro', label: 'Ouro' },
  { value: 'diamante', label: 'Diamante' }
];

export const MemberForm: React.FC<Props> = ({ initialData, onClose }) => {
  const { addMember, updateMember, removeMember } = useTeamStore();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState<Partial<TeamMember>>(
    initialData || {
      name: '',
      email: '',
      role: 'designer',
      status: 'active',
      rank: 'ferro',
      pageAccess: AVAILABLE_PAGES.map(page => ({
        resource: page.path,
        can_view: true,
        can_edit: false,
        can_delete: false,
        can_manage: false
      }))
    }
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.role) {
      setError('Por favor, preencha todos os campos obrigatórios');
      return;
    }

    try {
      if (initialData) {
        await updateMember(initialData.id, formData);
      } else {
        const memberData = {
          ...formData,
          status: formData.status || 'active',
          pageAccess: formData.pageAccess || AVAILABLE_PAGES.map(page => ({
            resource: page.path,
            can_view: true,
            can_edit: false,
            can_delete: false,
            can_manage: false
          }))
        };
        await addMember(memberData as Omit<TeamMember, 'id' | 'created_at' | 'updated_at'>);
      }
      onClose();
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Erro ao salvar membro');
      }
      console.error(err);
    }
  };

  const handleDelete = async () => {
    if (!initialData) return;
    try {
      await removeMember(initialData.id);
      onClose();
    } catch (err) {
      setError('Erro ao excluir membro');
      console.error(err);
    }
  };

  const togglePermission = (resource: string, permission: keyof Omit<PageAccess, 'resource'>) => {
    setFormData(prev => ({
      ...prev,
      pageAccess: prev.pageAccess?.map(page => 
        page.resource === resource
          ? {
              ...page,
              [permission]: !page[permission]
            }
          : page
      )
    }));
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50"
    >
      <motion.div
        initial={{ scale: 0.95 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.95 }}
        className="w-full max-w-4xl glass-card p-6 max-h-[90vh] overflow-y-auto"
      >
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-lg font-medium text-white">
            {initialData ? 'Editar Membro' : 'Novo Membro'}
          </h3>
          <div className="flex items-center gap-2">
            {initialData && (
              <button
                onClick={() => setShowDeleteModal(true)}
                className="p-2 rounded-lg text-red-400 hover:bg-red-500/20 transition-colors"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            )}
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-white transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {/* Personal Information */}
            <div className="space-y-4">
              <h4 className="text-sm font-medium text-gray-300 flex items-center gap-2">
                <Users className="w-4 h-4" />
                Informações Pessoais
              </h4>

              <div className="flex items-center gap-6">
                <PhotoUpload
                  currentPhotoUrl={formData.photoUrl}
                  onUpload={(file) => {
                    const url = URL.createObjectURL(file);
                    setFormData({ ...formData, photoUrl: url });
                  }}
                  onRemove={() => setFormData({ ...formData, photoUrl: undefined })}
                  size="lg"
                />
                
                <div className="space-y-4">
                  <h4 className="text-sm font-medium text-gray-300">
                    Ranking
                  </h4>
                  <div className="flex flex-wrap gap-3">
                    {RANKS.map(rank => (
                      <button
                        key={rank.value}
                        type="button"
                        onClick={() => setFormData({ ...formData, rank: rank.value })}
                        className={cn(
                          "transition-transform",
                          formData.rank === rank.value && "scale-110"
                        )}
                      >
                        <RankBadge 
                          rank={rank.value}
                          size="sm"
                        />
                      </button>
                    ))}
                  </div>
                </div>
              </div>

              <div>
                <label className="block text-sm text-gray-400 mb-1">Nome</label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="w-full px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-white"
                  required
                />
              </div>

              <div>
                <label className="block text-sm text-gray-400 mb-1">Email</label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="w-full px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-white"
                  required
                />
              </div>

              <div>
                <label className="block text-sm text-gray-400 mb-1">Função</label>
                <select
                  value={formData.role}
                  onChange={(e) => setFormData({ ...formData, role: e.target.value as Role })}
                  className="w-full px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-white"
                >
                  {ROLES.map(role => (
                    <option key={role.value} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm text-gray-400 mb-1">Status</label>
                <select
                  value={formData.status}
                  onChange={(e) => setFormData({ ...formData, status: e.target.value as TeamMember['status'] })}
                  className="w-full px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-white"
                >
                  <option value="active">Ativo</option>
                  <option value="pending">Pendente</option>
                  <option value="inactive">Inativo</option>
                </select>
              </div>

              {/* Rank Selection */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-200 mb-2">
                  <Shield className="w-4 h-4 inline-block mr-2" />
                  Rank
                </label>
                <div className="grid grid-cols-2 gap-2">
                  {RANKS.map(rank => (
                    <button
                      key={rank.value}
                      type="button"
                      onClick={() => setFormData({ ...formData, rank: rank.value })}
                      className={`
                        p-2 rounded-lg border transition-all
                        ${formData.rank === rank.value
                          ? 'border-blue-500 bg-blue-500/10'
                          : 'border-gray-700 hover:border-gray-600'
                        }
                      `}
                    >
                      <RankBadge rank={rank.value} />
                    </button>
                  ))}
                </div>
              </div>
            </div>

            {/* Page Access */}
            <div className="space-y-4">
              <h3 className="text-lg font-semibold text-white">Permissões de Acesso</h3>
              <div className="space-y-4">
                {AVAILABLE_PAGES.map(page => (
                  <div key={page.path} className="bg-gray-800 rounded-lg p-4">
                    <div className="flex items-center justify-between mb-3">
                      <span className="text-white font-medium">{page.name}</span>
                    </div>
                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                      {Object.entries({
                        can_view: 'Visualizar',
                        can_edit: 'Editar',
                        can_delete: 'Excluir',
                        can_manage: 'Gerenciar'
                      }).map(([permission, label]) => (
                        <label
                          key={permission}
                          className="flex items-center space-x-2 text-sm text-gray-300 cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            checked={formData.pageAccess?.find(p => p.resource === page.path)?.[permission as keyof Omit<PageAccess, 'resource'>] || false}
                            onChange={() => togglePermission(page.path, permission as keyof Omit<PageAccess, 'resource'>)}
                            className="form-checkbox h-4 w-4 text-blue-500 rounded border-gray-600 bg-gray-700 focus:ring-blue-500"
                          />
                          <span>{label}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {error && (
            <div className="flex items-center gap-2 p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400">
              <AlertCircle className="w-5 h-5" />
              <span>{error}</span>
            </div>
          )}

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-300 hover:text-white transition-colors"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="flex items-center gap-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors"
            >
              <Save className="w-4 h-4" />
              {initialData ? 'Salvar Alterações' : 'Criar Membro'}
            </button>
          </div>
        </form>
      </motion.div>

      {showDeleteModal && (
        <DeleteConfirmationModal
          memberName={formData.name || ''}
          onConfirm={handleDelete}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
    </motion.div>
  );
};