import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseKey) {
  throw new Error('Missing Supabase environment variables');
}

// Main client for authenticated operations
export const supabase = createClient(supabaseUrl, supabaseKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true,
    flowType: 'pkce'
  },
  db: {
    schema: 'public'
  }
});

// Simplified storage initialization that assumes bucket is already created in dashboard
export const uploadTeamPhoto = async (file: File, memberId: string): Promise<string> => {
  try {
    // Validate file size
    if (file.size > 2097152) { // 2MB
      throw new Error('Arquivo deve ter no máximo 2MB');
    }

    // Validate file type
    if (!['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
      throw new Error('Arquivo deve ser JPEG, PNG ou WebP');
    }

    const fileExt = file.name.split('.').pop();
    const fileName = `${memberId}-${Date.now()}.${fileExt}`;
    const filePath = `profile/${fileName}`;

    // Upload file
    const { data, error: uploadError } = await supabase.storage
      .from('team-photos')
      .upload(filePath, file, {
        cacheControl: '3600',
        upsert: true
      });

    if (uploadError) {
      console.error('Upload error:', uploadError);
      throw uploadError;
    }

    // Get public URL
    const { data: { publicUrl } } = supabase.storage
      .from('team-photos')
      .getPublicUrl(filePath);

    if (!publicUrl) {
      throw new Error('Erro ao obter URL pública');
    }

    return publicUrl;
  } catch (error) {
    console.error('Error uploading team photo:', error);
    throw error;
  }
};