import { supabase } from '../lib/supabase';
import type { FormData } from '../types/form';
import { createStore } from './production';

export const saveFormResponse = async (formData: FormData, formId: number = 1) => {
  try {
    // Save form response
    const { data, error } = await supabase
      .from('form_responses')
      .insert([
        {
          form_id: formId,
          response_data: formData,
          status: 'new'
        }
      ])
      .select()
      .single();

    if (error) {
      console.error('Error saving form response:', error);
      throw error;
    }

    // Add to production store
    await createStore(formData);

    return data;
  } catch (error: any) {
    console.error('Error in saveFormResponse:', error);
    throw error;
  }
};

export const getFormResponses = async (formId: number) => {
  try {
    const { data, error } = await supabase
      .from('form_responses')
      .select('*')
      .eq('form_id', formId)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching form responses:', error);
      throw error;
    }

    return data || [];
  } catch (error: any) {
    console.error('Error in getFormResponses:', error);
    throw error;
  }
};

export const updateFormStatus = async (id: string, status: string) => {
  try {
    console.log('Atualizando status via RPC:', { id, status });

    const { data, error } = await supabase
      .rpc('update_form_response_status', {
        response_id: id,
        new_status: status
      });

    if (error) {
      console.error('Error updating form status:', error);
      throw new Error(error.message);
    }

    if (!data || data.length === 0) {
      console.error('No data returned from update');
      throw new Error('Resposta não encontrada');
    }

    const updatedResponse = data[0];
    console.log('Status atualizado com sucesso:', updatedResponse);
    return updatedResponse;
  } catch (error: any) {
    console.error('Error in updateFormStatus:', error);
    throw error;
  }
};

export const deleteFormResponse = async (id: string) => {
  try {
    const { error } = await supabase
      .from('form_responses')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error deleting form response:', error);
      throw error;
    }

    return true;
  } catch (error: any) {
    console.error('Error in deleteFormResponse:', error);
    throw error;
  }
};