import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Users, UserPlus, Tag, LayoutGrid, List } from 'lucide-react';
import { useTeamStore } from '../../stores/teamStore';
import { MemberForm } from '../team/MemberForm';
import { MemberList } from '../team/MemberList';
import { TeamOverview } from '../team/TeamOverview';
import type { TeamMember } from '../../types/team';

export const TeamManagement: React.FC = () => {
  const [showNewMemberModal, setShowNewMemberModal] = useState(false);
  const [editingMember, setEditingMember] = useState<TeamMember | null>(null);
  const [viewMode, setViewMode] = useState<'list' | 'overview'>('overview');

  const handleEdit = (member: TeamMember) => {
    setEditingMember(member);
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold text-white">Gerenciamento de Equipe</h2>
        <div className="flex items-center gap-3">
          <div className="flex items-center bg-gray-800 rounded-lg p-1">
            <button
              onClick={() => setViewMode('overview')}
              className={`p-2 rounded-lg transition-colors ${
                viewMode === 'overview'
                  ? 'bg-blue-500 text-white'
                  : 'text-gray-400 hover:text-white'
              }`}
            >
              <LayoutGrid className="w-4 h-4" />
            </button>
            <button
              onClick={() => setViewMode('list')}
              className={`p-2 rounded-lg transition-colors ${
                viewMode === 'list'
                  ? 'bg-blue-500 text-white'
                  : 'text-gray-400 hover:text-white'
              }`}
            >
              <List className="w-4 h-4" />
            </button>
          </div>
          <button
            onClick={() => setShowNewMemberModal(true)}
            className="flex items-center gap-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors"
          >
            <UserPlus className="w-4 h-4" />
            Novo Membro
          </button>
        </div>
      </div>

      {viewMode === 'overview' ? (
        <TeamOverview />
      ) : (
        <MemberList onEdit={handleEdit} />
      )}

      {(showNewMemberModal || editingMember) && (
        <MemberForm
          initialData={editingMember || undefined}
          onClose={() => {
            setShowNewMemberModal(false);
            setEditingMember(null);
          }}
        />
      )}
    </div>
  );
};