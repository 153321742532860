import React from 'react';
import { motion } from 'framer-motion';

export const Footer: React.FC = () => {
  return (
    <motion.footer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="absolute bottom-0 left-0 right-0 py-4 md:py-6"
    >
      <div className="container mx-auto px-4 md:px-6">
        <p className="text-center text-xs md:text-sm text-gray-400 font-space-grotesk">
          Desenvolvido por Sabino Vision. Todos os direitos reservados.
        </p>
      </div>
    </motion.footer>
  );
};