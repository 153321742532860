import { WhatsAppTemplate } from './types';

export const templates: Record<string, WhatsAppTemplate> = {
  newForm: {
    name: 'new_form',
    format: (data) => `Olá ${data.fullName.split(' ')[0]}, tudo bem? 👋

Recebemos seu formulário para criação da loja ${data.storeName}! 🎉

Em breve iniciaremos o desenvolvimento. Fique tranquilo que manteremos você atualizado sobre cada etapa! 🚀`
  },
  stageComplete: {
    name: 'stage_complete',
    format: (data) => `Ótimas notícias! 🎉

A etapa "${data.stage}" da sua loja ${data.storeName} foi concluída!

Continuaremos trabalhando para entregar sua loja incrível no prazo! 🚀`
  },
  storeComplete: {
    name: 'store_complete',
    format: (data) => `Parabéns! 🎉

Sua loja ${data.storeName} está pronta!

Acesse agora mesmo: ${data.storeUrl}

Qualquer dúvida estamos à disposição! 🚀`
  },
  deadline: {
    name: 'deadline',
    format: (data) => `Olá! Passando para avisar que faltam ${data.daysRemaining} dias para a entrega da sua loja ${data.storeName}.

Estamos trabalhando para entregar tudo no prazo! 🚀`
  }
};