import { AutomationRule } from './types';

export const defaultRules: AutomationRule[] = [
  {
    id: 'new-form-notification',
    name: 'Notificação de Novo Formulário',
    description: 'Envia notificações quando um novo formulário é recebido',
    trigger: 'form_submission',
    actions: [
      {
        type: 'whatsapp',
        config: {
          template: 'newForm'
        }
      },
      {
        type: 'notification',
        config: {
          title: 'Novo Formulário',
          type: 'success'
        }
      },
      {
        type: 'timeline',
        config: {
          type: 'milestone',
          title: 'Formulário Recebido'
        }
      }
    ],
    enabled: true
  },
  {
    id: 'stage-completion',
    name: 'Conclusão de Etapa',
    description: 'Notifica quando uma etapa é concluída',
    trigger: 'stage_completion',
    actions: [
      {
        type: 'whatsapp',
        config: {
          template: 'stageComplete'
        }
      },
      {
        type: 'timeline',
        config: {
          type: 'milestone',
          title: 'Etapa Concluída'
        }
      }
    ],
    enabled: true
  },
  {
    id: 'deadline-alert',
    name: 'Alerta de Prazo',
    description: 'Monitora e alerta sobre prazos próximos',
    trigger: 'deadline',
    actions: [
      {
        type: 'notification',
        config: {
          title: 'Prazo Próximo',
          type: 'warning'
        }
      },
      {
        type: 'whatsapp',
        config: {
          template: 'deadline'
        }
      }
    ],
    enabled: true
  }
];