import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export const Header: React.FC = () => {
  return (
    <motion.header
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="absolute top-0 left-0 right-0 z-50 py-4 md:py-6"
    >
      <div className="container mx-auto px-4 md:px-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2 md:gap-3">
            <Link to="/login" className="w-24 md:w-32 transition-opacity hover:opacity-90">
              <img 
                src="https://woopremium.site/wp-content/uploads/2024/11/Logo-Branco.png.webp" 
                alt="Vision" 
                className="w-full h-auto" 
              />
            </Link>
          </div>
          
          <div className="flex items-center gap-2 md:gap-3">
            <div className="w-1.5 h-1.5 md:w-2 md:h-2 rounded-full bg-[#2BA6FF] animate-glow" />
            <span className="text-xs md:text-sm text-gray-400 font-space-grotesk">Vision AI Powered</span>
          </div>
        </div>
      </div>
    </motion.header>
  );
};