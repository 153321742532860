import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Camera, X, Check, Image as ImageIcon, Upload, Loader } from 'lucide-react';
import { uploadTeamPhoto } from '../../lib/supabase';
import { useAuthStore } from '../../stores/authStore';
import { cn } from '../../lib/utils';

interface Props {
  currentPhotoUrl?: string;
  onUpload: (url: string) => void;
  onRemove?: () => void;
  size?: 'sm' | 'md' | 'lg';
  memberId?: string;
}

export const PhotoUpload: React.FC<Props> = ({ 
  currentPhotoUrl, 
  onUpload, 
  onRemove,
  size = 'md',
  memberId
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const updateAuthPhoto = useAuthStore(state => state.updatePhoto);

  const handleUpload = async (file: File) => {
    if (!file.type.startsWith('image/')) {
      setError('Por favor, selecione uma imagem');
      return;
    }

    const maxSize = 2 * 1024 * 1024; // 2MB
    if (file.size > maxSize) {
      setError('A imagem deve ter no máximo 2MB');
      return;
    }

    try {
      setIsUploading(true);
      setError(null);

      const publicUrl = await uploadTeamPhoto(file, memberId || 'profile');

      // Update local state
      onUpload(publicUrl);
      updateAuthPhoto(publicUrl);

    } catch (err) {
      console.error('Error uploading photo:', err);
      setError('Erro ao fazer upload da foto');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="relative">
      <input
        ref={fileInputRef}
        type="file"
        accept="image/jpeg,image/png,image/webp"
        className="hidden"
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file) handleUpload(file);
        }}
      />

      <div
        onClick={() => !isUploading && fileInputRef.current?.click()}
        onDrop={(e) => {
          e.preventDefault();
          setIsDragging(false);
          const file = e.dataTransfer.files[0];
          if (file) handleUpload(file);
        }}
        onDragOver={(e) => {
          e.preventDefault();
          setIsDragging(true);
        }}
        onDragLeave={() => setIsDragging(false)}
        className={cn(
          "relative cursor-pointer transition-all rounded-full overflow-hidden",
          isDragging ? "ring-2 ring-blue-500 scale-105" : "hover:opacity-90",
          isUploading && "cursor-wait",
          size === 'sm' && "w-12 h-12",
          size === 'md' && "w-16 h-16",
          size === 'lg' && "w-24 h-24"
        )}
      >
        {currentPhotoUrl ? (
          <img
            src={currentPhotoUrl}
            alt="Profile"
            className="w-full h-full object-cover"
          />
        ) : (
          <div className={cn(
            "w-full h-full bg-gray-700 flex items-center justify-center",
            isDragging && "bg-gray-600"
          )}>
            <Camera className={cn(
              "text-gray-400",
              size === 'sm' && "w-4 h-4",
              size === 'md' && "w-6 h-6",
              size === 'lg' && "w-8 h-8"
            )} />
          </div>
        )}

        {isUploading && (
          <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
            <Loader className="w-6 h-6 text-white animate-spin" />
          </div>
        )}

        {!isUploading && (
          <div className="absolute inset-0 bg-black/50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity">
            <Upload className={cn(
              "text-white",
              size === 'sm' && "w-4 h-4",
              size === 'md' && "w-5 h-5",
              size === 'lg' && "w-6 h-6"
            )} />
          </div>
        )}
      </div>

      {error && (
        <div className="absolute top-full left-0 right-0 mt-2 p-2 text-xs text-red-400 bg-red-500/10 rounded-lg">
          {error}
        </div>
      )}

      {currentPhotoUrl && onRemove && !isUploading && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }}
          className="absolute -top-1 -right-1 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
        >
          <X className="w-3 h-3" />
        </button>
      )}
    </div>
  );
};