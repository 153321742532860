import { supabase } from '../../lib/supabase';
import type { FormData } from '../../types/form';
import type { ProductionItem } from '../../stores/productionStore';

const initialStatus = {
  visualIdentity: 'pending',
  banners: 'pending',
  information: 'pending',
  collectionCovers: 'pending',
  socialMedia: 'pending',
  motion: 'pending',
  checkout: 'pending',
  refinement: 'pending',
  storeConfig: 'pending'
};

export const createProductionItem = async (formData: FormData) => {
  try {
    // Validação inicial
    if (!formData.storeName) {
      throw new Error('Nome da loja é obrigatório');
    }

    // Verificar se já existe uma loja com o mesmo nome
    const { data: existingStore } = await supabase
      .from('store_production')
      .select('store_name')
      .eq('store_name', formData.storeName)
      .single();

    if (existingStore) {
      throw new Error('Uma loja com este nome já existe');
    }

    const storeData = {
      store_name: formData.storeName.trim(),
      store_type: formData.storeFocus === 'Loja de Nicho' ? 'niche' : 'generic',
      platform: 'shopify',
      plan: formData.plan || 'start',
      status: initialStatus,
      assigned_to: null, // Começar como null ao invés de UUID
      purchase_date: new Date().toISOString(),
      expected_delivery_date: new Date(Date.now() + 15 * 24 * 60 * 60 * 1000).toISOString(),
      client: {
        name: formData.fullName || 'Cliente Novo',
        email: formData.email || 'cliente@email.com',
        phone: formData.whatsapp || '(00) 00000-0000'
      },
      custom_requirements: formData.additionalNotes || '',
      priority: 'medium',
      tags: formData.personality || [],
      payment_status: 'pending',
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      drive_link: null,
      completed_store_link: null,
      revisions: 0,
      payment_value: null,
      payment_method: null,
      responsible_designer: null,
      responsible_developer: null,
      integrations: []
    };

    // Inserir dados e retornar o resultado em uma única operação
    const { data, error } = await supabase
      .from('store_production')
      .insert(storeData)
      .select()
      .single();

    if (error) {
      console.error('Error inserting store:', error);
      if (error.code === '23505') {
        throw new Error('Uma loja com este nome já existe');
      } else if (error.code === '23514') {
        throw new Error('Valor inválido para o plano da loja');
      } else {
        throw new Error(`Erro ao criar loja: ${error.message}`);
      }
    }

    if (!data) {
      throw new Error('Nenhum dado retornado ao criar a loja');
    }

    return data;
  } catch (error) {
    console.error('Error in createProductionItem:', error);
    if (error instanceof Error) {
      throw error;
    } else {
      throw new Error('Erro desconhecido ao criar loja');
    }
  }
};

export const getAllProductionItems = async () => {
  try {
    const { data, error } = await supabase
      .from('store_production')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching production items:', error);
      throw error;
    }

    return data || [];
  } catch (error) {
    console.error('Error in getAllProductionItems:', error);
    throw error;
  }
};

export const updateProductionItem = async (id: string, updates: Partial<ProductionItem>) => {
  try {
    const { data, error } = await supabase
      .from('store_production')
      .update({
        ...updates,
        updated_at: new Date().toISOString()
      })
      .eq('id', id)
      .select()
      .single();

    if (error) {
      console.error('Error updating production item:', error);
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error in updateProductionItem:', error);
    throw error;
  }
};