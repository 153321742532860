import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { supabase } from '../lib/supabase';

interface AuthState {
  isAuthenticated: boolean;
  user: string | null;
  photoUrl: string | null;
  role: string | null;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
  updatePhoto: (photoUrl: string) => void;
  checkSession: () => Promise<void>;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      isAuthenticated: false,
      user: null,
      photoUrl: null,
      role: null,

      checkSession: async () => {
        const { data: { session } } = await supabase.auth.getSession();
        
        if (session) {
          const { data: teamMember } = await supabase
            .from('team_members')
            .select('*')
            .eq('email', session.user.email)
            .single();

          if (teamMember) {
            set({ 
              isAuthenticated: true, 
              user: teamMember.name,
              role: teamMember.user_role,
              photoUrl: teamMember.photo_url || null
            });
          }
        } else {
          set({ 
            isAuthenticated: false, 
            user: null,
            role: null,
            photoUrl: null
          });
        }
      },

      login: async (email: string, password: string) => {
        try {
          const { data, error } = await supabase.auth.signInWithPassword({
            email,
            password
          });

          if (error) {
            console.error('Login error:', error);
            return false;
          }

          if (data.user) {
            // Buscar informações do usuário na tabela team_members
            const { data: teamMember, error: teamError } = await supabase
              .from('team_members')
              .select('*')
              .eq('email', email)
              .single();

            if (teamError) {
              console.error('Team member fetch error:', teamError);
              return false;
            }

            set({ 
              isAuthenticated: true, 
              user: teamMember.name,
              role: teamMember.user_role,
              photoUrl: teamMember.photo_url || null
            });

            return true;
          }

          return false;
        } catch (error) {
          console.error('Login error:', error);
          return false;
        }
      },

      logout: async () => {
        try {
          const { error } = await supabase.auth.signOut();
          if (error) {
            console.error('Logout error:', error);
          }
          set({ 
            isAuthenticated: false, 
            user: null,
            role: null,
            photoUrl: null
          });
        } catch (error) {
          console.error('Logout error:', error);
        }
      },

      updatePhoto: (photoUrl: string) => {
        set({ photoUrl });
      }
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        photoUrl: state.photoUrl,
        role: state.role
      })
    }
  )
);