import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: 'sk-zhNChzvw-j5AMhsc6O0osT3zma1BcDJm5tGM6qnTZ-T3BlbkFJINRarDxq6x7xJ5iRQevzTxsDW6aV3wlqyKK-56k24A',
  dangerouslyAllowBrowser: true
});

const VISION_AI_PROMPT = `Você é a Vision AI, especialista em branding que combina estratégia e criatividade.

Para sugestões de nome:
- Seja conciso e direto
- Máximo de 2 linhas de explicação
- Foque no diferencial principal
- Evite explicações técnicas
- Use o idioma apropriado para a região

Para sugestões de cores:
- Análise objetiva do significado
- Conexão clara com o nicho
- Impacto na conversão
- Máximo de 3 parágrafos

Tom de voz:
- Profissional mas acessível
- Direto e objetivo
- Criativo mas prático`;

const getLanguagePrompt = (region: string) => {
  switch (region) {
    case 'brazil':
      return 'em português do Brasil';
    case 'latam':
      return 'en español';
    case 'europe':
      return 'in English';
    default:
      return 'in English';
  }
};

export async function generateBrandSuggestion(
  type: 'name' | 'colors',
  context: {
    nicheMarket: string;
    targetAudience: string;
    storeFocus: string;
    region?: string;
    personality?: string[];
  }
) {
  const language = getLanguagePrompt(context.region || 'brazil');
  const messages = [
    { role: 'system', content: VISION_AI_PROMPT },
    {
      role: 'user',
      content: type === 'name' 
        ? `Sugira 3 nomes criativos ${language} para uma ${context.storeFocus} de ${context.nicheMarket} focada em ${context.targetAudience}. 
           Para cada nome:
           - Nome único e memorável
           - Uma frase curta explicando o conceito em português BR`
        : `Desenvolva uma paleta para uma ${context.storeFocus} de ${context.nicheMarket} focada em ${context.targetAudience}. 
           Características: ${context.personality?.join(', ')}.
           
           Formato da resposta:
           1. Cor primária: #HEX - significado
           2. Cor destaque: #HEX - propósito
           3. Breve análise do impacto no nicho
           4. Estratégia de conversão em uma frase`
    }
  ];

  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4-0125-preview",
      messages: messages as any,
      temperature: 0.8,
      max_tokens: 500,
      presence_penalty: 0.3,
      frequency_penalty: 0.5
    });

    const response = completion.choices[0].message.content;

    if (type === 'name') {
      const suggestions = response?.split('\n\n').map(suggestion => {
        const [name, explanation] = suggestion.split('\n');
        return {
          name: name.replace(/^\d+\.\s*/, '').replace(/^Nome:\s*/, ''),
          explanation: explanation?.replace(/^-\s*/, '') || ''
        };
      });
      return suggestions || [];
    } else {
      const lines = response?.split('\n');
      const primary = lines?.find(l => l.toLowerCase().includes('primária'))?.match(/#[0-9A-Fa-f]{6}/)?.[0] || '#1a1a1a';
      const accent = lines?.find(l => l.toLowerCase().includes('destaque'))?.match(/#[0-9A-Fa-f]{6}/)?.[0] || '#3b82f6';
      
      return {
        primary,
        accent,
        analysis: response || 'Erro ao gerar análise de cores'
      };
    }
  } catch (error) {
    console.error('Error generating suggestion:', error);
    return type === 'name' 
      ? [{ name: 'Erro ao gerar nomes', explanation: 'Por favor, tente novamente' }]
      : { primary: '#1a1a1a', accent: '#3b82f6', analysis: 'Erro ao gerar cores' };
  }
}