import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Bell, X, CheckCircle, AlertTriangle, AlertCircle, Info } from 'lucide-react';
import { useNotificationStore } from '../../stores/notificationStore';
import { cn } from '../../lib/utils';

export const NotificationToast: React.FC = () => {
  const { notifications, markAsRead } = useNotificationStore();
  const latestNotification = notifications[0];

  useEffect(() => {
    if (latestNotification && !latestNotification.read) {
      const timer = setTimeout(() => {
        markAsRead(latestNotification.id);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [latestNotification]);

  if (!latestNotification || latestNotification.read) return null;

  const getIcon = () => {
    switch (latestNotification.type) {
      case 'success':
        return <CheckCircle className="w-5 h-5 text-green-400" />;
      case 'warning':
        return <AlertTriangle className="w-5 h-5 text-yellow-400" />;
      case 'error':
        return <AlertCircle className="w-5 h-5 text-red-400" />;
      default:
        return <Info className="w-5 h-5 text-blue-400" />;
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="fixed top-4 right-4 z-50 max-w-sm"
      >
        <div className={cn(
          "p-4 rounded-lg shadow-lg backdrop-blur-xl border",
          latestNotification.type === 'success' && "bg-green-500/10 border-green-500/20",
          latestNotification.type === 'warning' && "bg-yellow-500/10 border-yellow-500/20",
          latestNotification.type === 'error' && "bg-red-500/10 border-red-500/20",
          latestNotification.type === 'info' && "bg-blue-500/10 border-blue-500/20"
        )}>
          <div className="flex items-start gap-3">
            {getIcon()}
            <div className="flex-1">
              <h4 className="text-white font-medium">{latestNotification.title}</h4>
              <p className="text-sm text-gray-300 mt-1">{latestNotification.message}</p>
            </div>
            <button
              onClick={() => markAsRead(latestNotification.id)}
              className="text-gray-400 hover:text-white transition-colors"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};