import React from 'react';
import { motion } from 'framer-motion';
import { Trophy, Medal, Crown } from 'lucide-react';
import { useTeamStore } from '../../stores/teamStore';
import { useAchievementStore } from '../../stores/achievementStore';
import { RankBadge } from './RankBadge';
import { cn } from '../../lib/utils';

export const TeamRanking: React.FC = () => {
  const { members } = useTeamStore();
  const { getMemberProgress } = useAchievementStore();

  // Ordena membros por XP
  const rankedMembers = [...members]
    .map(member => ({
      ...member,
      progress: getMemberProgress(member.id)
    }))
    .sort((a, b) => (b.progress?.xp || 0) - (a.progress?.xp || 0))
    .slice(0, 10); // Top 10

  return (
    <div className="space-y-6">
      <h3 className="text-lg font-medium text-white flex items-center gap-2">
        <Trophy className="w-5 h-5 text-yellow-400" />
        Ranking da Equipe
      </h3>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Top 3 Podium */}
        <div className="md:col-span-3 flex items-end justify-center gap-4 h-48">
          {rankedMembers.slice(0, 3).map((member, index) => {
            const height = index === 1 ? 'h-32' : index === 2 ? 'h-24' : 'h-40';
            const position = index + 1;

            return (
              <motion.div
                key={member.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                className="relative flex flex-col items-center"
              >
                <div className="absolute -top-4">
                  {position === 1 ? (
                    <Crown className="w-8 h-8 text-yellow-400" />
                  ) : position === 2 ? (
                    <Medal className="w-6 h-6 text-gray-300" />
                  ) : (
                    <Medal className="w-6 h-6 text-yellow-600" />
                  )}
                </div>

                <div className="flex flex-col items-center mb-2">
                  {member.photoUrl ? (
                    <img
                      src={member.photoUrl}
                      alt={member.name}
                      className="w-12 h-12 rounded-full object-cover ring-2 ring-gray-700"
                    />
                  ) : (
                    <div className="w-12 h-12 rounded-full bg-gray-700 flex items-center justify-center ring-2 ring-gray-600">
                      <span className="text-lg font-medium text-white">
                        {member.name.charAt(0)}
                      </span>
                    </div>
                  )}
                  <span className="text-sm text-white mt-2">{member.name}</span>
                  <RankBadge rank={member.rank} size="sm" />
                </div>

                <div className={cn(
                  "w-24 rounded-t-lg",
                  height,
                  position === 1 ? "bg-yellow-500/20" :
                  position === 2 ? "bg-gray-500/20" :
                  "bg-yellow-600/20"
                )}>
                  <div className="text-center mt-2">
                    <span className="text-2xl font-bold text-white">#{position}</span>
                    <div className="text-sm text-gray-400">
                      {member.progress?.xp.toLocaleString()} XP
                    </div>
                  </div>
                </div>
              </motion.div>
            );
          })}
        </div>

        {/* Ranking List */}
        <div className="md:col-span-3 space-y-2">
          {rankedMembers.slice(3).map((member, index) => {
            const position = index + 4;

            return (
              <motion.div
                key={member.id}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-center gap-4 p-3 rounded-lg bg-gray-800/50"
              >
                <span className="w-8 text-center font-bold text-gray-400">
                  #{position}
                </span>

                <div className="flex items-center gap-3 flex-1">
                  {member.photoUrl ? (
                    <img
                      src={member.photoUrl}
                      alt={member.name}
                      className="w-8 h-8 rounded-full object-cover"
                    />
                  ) : (
                    <div className="w-8 h-8 rounded-full bg-gray-700 flex items-center justify-center">
                      <span className="text-sm font-medium text-white">
                        {member.name.charAt(0)}
                      </span>
                    </div>
                  )}
                  <span className="text-white">{member.name}</span>
                </div>

                <div className="flex items-center gap-3">
                  <RankBadge rank={member.rank} size="sm" />
                  <span className="text-gray-400">
                    {member.progress?.xp.toLocaleString()} XP
                  </span>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>
    </div>
  );
};