import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { X, Plus, AlertCircle } from 'lucide-react';
import { createStore } from '../../services/production';

interface Props {
  onClose: () => void;
}

export const NewStoreModal: React.FC<Props> = ({ onClose }) => {
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    storeName: '',
    storeFocus: 'Loja Genérica',
    plan: 'start',
    fullName: 'Cliente Novo',
    email: 'cliente@email.com',
    whatsapp: '(00) 00000-0000',
    additionalNotes: '',
    personality: []
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    
    if (!formData.storeName) {
      setError('Por favor, preencha o nome da loja');
      return;
    }

    try {
      const result = await createStore(formData);
      if (result) {
        onClose();
      }
    } catch (err) {
      console.error('Error creating store:', err);
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Erro ao criar loja');
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50"
    >
      <motion.div
        initial={{ scale: 0.95 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.95 }}
        className="w-full max-w-lg glass-card p-6"
      >
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-lg font-medium text-white">Nova Loja</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {error && (
          <div className="flex items-center gap-2 p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400 mb-6">
            <AlertCircle className="w-5 h-5" />
            <span>{error}</span>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Store Information */}
          <div className="space-y-4">
            <div>
              <label className="block text-sm text-gray-400 mb-1">Nome da Loja *</label>
              <input
                type="text"
                value={formData.storeName}
                onChange={(e) => setFormData({ ...formData, storeName: e.target.value })}
                className="w-full px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-white"
                required
              />
            </div>
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-300 hover:text-white transition-colors"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="flex items-center gap-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors"
            >
              <Plus className="w-4 h-4" />
              Criar Loja
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};