import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  Download, 
  Filter, 
  AlertCircle, 
  Loader,
  ChevronDown,
  ChevronUp,
  MessageSquare,
  Trash2,
  Search,
  CheckCircle2
} from 'lucide-react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { format, isAfter, startOfDay } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { getFormResponses, deleteFormResponse, updateFormStatus } from '../../services/database';
import { ResponseDetails } from './ResponseDetails';

const statusColors = {
  new: {
    bg: 'bg-blue-500/20',
    text: 'text-blue-300',
    border: 'border-blue-500/20'
  },
  in_progress: {
    bg: 'bg-purple-500/20',
    text: 'text-purple-300',
    border: 'border-purple-500/20'
  },
  completed: {
    bg: 'bg-green-500/20',
    text: 'text-green-300',
    border: 'border-green-500/20'
  }
};

const statusLabels = {
  new: 'Novo',
  in_progress: 'Produzindo',
  completed: 'Concluído'
};

export const FormResponses: React.FC = () => {
  const { formId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [error, setError] = useState<string | null>(null);
  const [expandedResponse, setExpandedResponse] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    status: '',
    date: '',
    storeType: ''
  });
  
  useEffect(() => {
    console.log('FormResponses montado com formId:', formId);
  }, [formId]);

  const { data: responses, isLoading } = useQuery({
    queryKey: ['formResponses', formId],
    queryFn: () => getFormResponses(Number(formId)),
    retry: 3,
    retryDelay: 1000,
    onSuccess: (data) => {
      console.log('Respostas carregadas com sucesso:', {
        formId,
        quantidade: data?.length || 0
      });
    },
    onError: (error: any) => {
      setError(error.message || 'Erro ao carregar as respostas.');
      console.error('Error fetching responses:', error);
    }
  });

  const updateStatusMutation = useMutation({
    mutationFn: async ({ id, status }: { id: string; status: string }) => {
      console.log('Iniciando mutação:', { id, status });
      const result = await updateFormStatus(id, status);
      console.log('Resultado da mutação:', result);
      return result;
    },
    onMutate: async ({ id, status }) => {
      console.log('onMutate:', { id, status });
      
      // Cancelar queries pendentes
      await queryClient.cancelQueries({ queryKey: ['formResponses', formId] });
      
      // Guardar o estado anterior
      const previousResponses = queryClient.getQueryData(['formResponses', formId]);
      
      // Atualizar o cache otimisticamente
      queryClient.setQueryData(['formResponses', formId], (old: any) => {
        if (!old) return old;
        const updated = old.map((response: any) =>
          response.id === id ? { ...response, status } : response
        );
        console.log('Cache atualizado otimisticamente:', updated);
        return updated;
      });

      return { previousResponses };
    },
    onError: (err, variables, context: any) => {
      console.log('onError:', err);
      
      // Reverter para o estado anterior
      if (context?.previousResponses) {
        queryClient.setQueryData(['formResponses', formId], context.previousResponses);
        console.log('Cache revertido para:', context.previousResponses);
      }
      
      // Mostrar mensagem de erro
      setError('Erro ao atualizar status. Por favor, tente novamente.');
    },
    onSuccess: (updatedResponse) => {
      console.log('onSuccess:', updatedResponse);
      
      // Atualizar o cache com os dados do servidor
      queryClient.setQueryData(['formResponses', formId], (old: any) => {
        if (!old) return old;
        const updated = old.map((response: any) =>
          response.id === updatedResponse.id ? updatedResponse : response
        );
        console.log('Cache atualizado com dados do servidor:', updated);
        return updated;
      });
      
      // Limpar mensagem de erro
      setError(null);
    },
    onSettled: () => {
      console.log('onSettled');
      queryClient.invalidateQueries({ queryKey: ['formResponses', formId] });
    },
  });

  const handleStatusChange = (id: string, status: string) => {
    console.log('handleStatusChange:', { id, status });
    updateStatusMutation.mutate({ id, status });
  };

  const deleteResponseMutation = useMutation({
    mutationFn: (id: string) => deleteFormResponse(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['formResponses'] });
    }
  });

  // Atualiza automaticamente o status para 'in_progress' quando a data muda
  useEffect(() => {
    if (!responses) return;

    const today = startOfDay(new Date());
    responses.forEach((response: any) => {
      if (response.status === 'new' && response.production_start_date && 
          isAfter(today, new Date(response.production_start_date))) {
        updateStatusMutation.mutate({ id: response.id, status: 'in_progress' });
      }
    });
  }, [responses]);

  const handleDelete = async (id: string) => {
    if (window.confirm('Tem certeza que deseja excluir esta resposta?')) {
      try {
        await deleteResponseMutation.mutateAsync(id);
      } catch (error) {
        console.error('Error deleting response:', error);
        setError('Erro ao excluir resposta.');
      }
    }
  };

  const handleWhatsAppMessage = (response: any) => {
    const phone = response.response_data.whatsapp.replace(/\D/g, '');
    const message = `Olá ${response.response_data.fullName.split(' ')[0]}, tudo bem? Vi que você preencheu o formulário para criação da sua loja!`;
    window.open(`https://wa.me/${phone}?text=${encodeURIComponent(message)}`, '_blank');
  };

  const handleExport = () => {
    if (!responses?.length) return;
    
    try {
      const headers = [
        'Nome Completo',
        'WhatsApp',
        'Foco da Loja',
        'Nicho',
        'Produtos',
        'Público Alvo',
        'Região',
        'Nome da Loja',
        'Cores',
        'Estilo da Marca',
        'Personalidade',
        'Status',
        'Data de Envio'
      ].join(',');

      const csv = [
        headers,
        ...responses.map(response => {
          const data = response.response_data;
          return [
            data.fullName,
            data.whatsapp,
            data.storeFocus,
            data.nicheMarket || '-',
            data.products,
            data.targetAudience,
            data.region,
            data.storeName,
            JSON.stringify(data.colors),
            data.brandStyle,
            data.personality.join('; '),
            response.status,
            format(new Date(response.created_at), 'dd/MM/yyyy HH:mm', { locale: ptBR })
          ].join(',');
        })
      ].join('\n');

      const blob = new Blob(['\ufeff' + csv], { type: 'text/csv;charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `respostas-formulario-${formId}-${format(new Date(), 'dd-MM-yyyy')}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Error exporting CSV:', err);
      setError('Erro ao exportar as respostas.');
    }
  };

  const filteredResponses = responses?.filter(response => {
    // Primeiro aplicar o filtro de status padrão (new e in_progress)
    if (filters.status) {
      // Se um status específico foi selecionado, usar esse filtro
      if (response.status !== filters.status) return false;
    } else {
      // Se nenhum status foi selecionado, mostrar apenas 'new' e 'in_progress'
      if (response.status === 'completed') return false;
    }

    // Aplicar os demais filtros
    if (searchTerm && !response.response_data.storeName.toLowerCase().includes(searchTerm.toLowerCase()) &&
        !response.response_data.fullName.toLowerCase().includes(searchTerm.toLowerCase())) {
      return false;
    }
    if (filters.storeType && response.response_data.storeFocus !== filters.storeType) return false;
    if (filters.date) {
      const responseDate = format(new Date(response.created_at), 'yyyy-MM-dd');
      if (responseDate !== filters.date) return false;
    }
    return true;
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <button
            onClick={() => navigate('/dashboard/forms')}
            className="p-2 rounded-lg text-gray-300 hover:text-white hover:bg-gray-700/50 transition-colors"
          >
            <ArrowLeft className="w-5 h-5" />
          </button>
          <h2 className="text-2xl font-bold text-white">Respostas do Formulário</h2>
        </div>
        <div className="flex items-center gap-3">
          <button
            onClick={handleExport}
            disabled={!responses?.length || isLoading}
            className="flex items-center gap-2 px-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <Download className="w-4 h-4" />
            <span>Exportar CSV</span>
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Buscar por nome da loja ou cliente..."
            className="w-full pl-10 pr-4 py-3 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-white placeholder-gray-400"
          />
        </div>

        <div className="flex items-center gap-4">
          <input
            type="date"
            value={filters.date}
            onChange={(e) => setFilters(prev => ({ ...prev, date: e.target.value }))}
            className="px-4 py-2 rounded-lg bg-gray-700/50 border border-gray-600 text-white"
          />
          <select
            value={filters.storeType}
            onChange={(e) => setFilters(prev => ({ ...prev, storeType: e.target.value }))}
            className="px-4 py-2 rounded-lg bg-gray-700/50 border border-gray-600 text-white"
          >
            <option value="">Tipo de Loja</option>
            <option value="Loja Geral">Loja Geral</option>
            <option value="Loja de Nicho">Loja de Nicho</option>
          </select>
          <select
            value={filters.status}
            onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
            className="px-4 py-2 rounded-lg bg-gray-700/50 border border-gray-600 text-white"
          >
            <option value="">Novo + Produzindo</option>
            <option value="new">Novo</option>
            <option value="in_progress">Produzindo</option>
            <option value="completed">Concluído</option>
          </select>
        </div>
      </div>

      {error && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex items-center gap-2 p-4 rounded-lg bg-red-500/10 border border-red-500/20 text-red-300"
        >
          <AlertCircle className="w-5 h-5" />
          <span>{error}</span>
        </motion.div>
      )}

      {isLoading ? (
        <div className="flex flex-col items-center justify-center h-64 gap-4">
          <Loader className="w-8 h-8 text-blue-500 animate-spin" />
          <p className="text-gray-400">Carregando respostas...</p>
        </div>
      ) : !filteredResponses?.length ? (
        <div className="text-center py-12">
          <p className="text-gray-400">Nenhuma resposta encontrada</p>
        </div>
      ) : (
        <div className="grid gap-4">
          {filteredResponses.map((response) => (
            <motion.div
              key={response.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="glass-card p-6 space-y-4"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <div>
                    <h3 className="text-lg font-medium text-white">
                      {response.response_data.storeName}
                    </h3>
                    <p className="text-sm text-gray-400">
                      {format(new Date(response.created_at), "dd 'de' MMMM 'de' yyyy 'às' HH:mm", { locale: ptBR })}
                    </p>
                  </div>
                </div>

                <div className="flex items-center gap-3">
                  <select
                    value={response.status}
                    onChange={(e) => handleStatusChange(response.id, e.target.value)}
                    className={`px-3 py-2 rounded-lg border transition-colors ${
                      statusColors[response.status as keyof typeof statusColors].bg
                    } ${statusColors[response.status as keyof typeof statusColors].text} ${
                      statusColors[response.status as keyof typeof statusColors].border
                    }`}
                  >
                    {Object.entries(statusLabels).map(([value, label]) => (
                      <option key={value} value={value}>{label}</option>
                    ))}
                  </select>

                  <button
                    onClick={() => handleWhatsAppMessage(response)}
                    className="p-2 rounded-lg text-gray-300 hover:text-white hover:bg-gray-700/50 transition-colors"
                    title="Enviar Mensagem"
                  >
                    <MessageSquare className="w-4 h-4" />
                  </button>

                  <button
                    onClick={() => handleDelete(response.id)}
                    className="p-2 rounded-lg text-gray-300 hover:text-red-400 hover:bg-gray-700/50 transition-colors"
                    title="Excluir"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>

                  <button
                    onClick={() => setExpandedResponse(
                      expandedResponse === response.id ? null : response.id
                    )}
                    className="p-2 rounded-lg text-gray-300 hover:text-white hover:bg-gray-700/50 transition-colors"
                  >
                    {expandedResponse === response.id ? (
                      <ChevronUp className="w-4 h-4" />
                    ) : (
                      <ChevronDown className="w-4 h-4" />
                    )}
                  </button>
                </div>
              </div>

              <AnimatePresence>
                {expandedResponse === response.id && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    className="border-t border-gray-700 pt-4 mt-4"
                  >
                    <ResponseDetails response={response} />
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </div>
      )}
    </motion.div>
  );
};