import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Store, Wand2, Sparkles, Loader } from 'lucide-react';
import { FormData } from '../../App';
import { generateBrandSuggestion } from '../../services/openai';

interface Props {
  formData: FormData;
  updateFormData: (field: keyof FormData, value: any) => void;
}

interface NameSuggestion {
  name: string;
  explanation: string;
}

export const StoreName: React.FC<Props> = ({ formData, updateFormData }) => {
  const [suggestions, setSuggestions] = useState<NameSuggestion[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const generateSuggestions = async () => {
    setIsLoading(true);
    try {
      const newSuggestions = await generateBrandSuggestion('name', {
        nicheMarket: formData.nicheMarket || '',
        targetAudience: formData.targetAudience || '',
        storeFocus: formData.storeFocus,
        region: formData.region
      });
      setSuggestions(newSuggestions);
      setShowSuggestions(true);
    } catch (error) {
      console.error('Error generating names:', error);
    }
    setIsLoading(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold text-white">Nome da Loja</h2>
        <button
          onClick={generateSuggestions}
          disabled={isLoading}
          className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
            isLoading
              ? 'bg-gray-700 text-gray-400 cursor-not-allowed'
              : 'bg-blue-500/20 text-blue-400 hover:bg-blue-500/30'
          }`}
        >
          {isLoading ? (
            <>
              <Loader className="w-4 h-4 animate-spin" />
              Gerando...
            </>
          ) : (
            <>
              <Wand2 className="w-4 h-4" />
              Sugestões do Vision AI
            </>
          )}
        </button>
      </div>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Qual será o nome da sua loja?
          </label>
          <div className="relative">
            <Store className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              value={formData.storeName}
              onChange={(e) => updateFormData('storeName', e.target.value)}
              className="w-full pl-10 pr-4 py-3 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-white placeholder-gray-400"
              placeholder="Digite o nome da sua loja"
            />
          </div>
        </div>

        <AnimatePresence>
          {showSuggestions && suggestions.length > 0 && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              className="space-y-3"
            >
              {suggestions.map((suggestion, index) => (
                <motion.button
                  key={index}
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.99 }}
                  onClick={() => updateFormData('storeName', suggestion.name)}
                  className="w-full p-3 bg-gray-800/50 rounded-lg text-left hover:bg-gray-800/70 transition-colors"
                >
                  <div className="text-lg font-medium text-white">
                    {suggestion.name}
                  </div>
                  {suggestion.explanation && (
                    <p className="text-sm text-gray-400 mt-1">
                      {suggestion.explanation}
                    </p>
                  )}
                </motion.button>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};