import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ProgressBar } from './ProgressBar';
import { PersonalInfo } from './steps/PersonalInfo';
import { StoreFocus } from './steps/StoreFocus';
import { StoreName } from './steps/StoreName';
import { BrandColors } from './steps/BrandColors';
import { BrandRepresentation } from './steps/BrandRepresentation';
import { DesignElements } from './steps/DesignElements';
import { StoreSettings } from './steps/StoreSettings';
import { Completion } from './steps/Completion';
import { Header } from './Header';
import { Footer } from './Footer';
import { ParticleBackground } from './ParticleBackground';
import { SubmitButton } from './SubmitButton';
import type { FormData } from '../types/form';

const initialFormData: FormData = {
  fullName: '',
  email: '',
  whatsapp: '',
  storeFocus: '',
  nicheMarket: '',
  products: '',
  targetAudience: '',
  region: 'brazil',
  referenceStore: '',
  storeName: '',
  colors: {
    primary: '#1a1a1a',
    secondary: '#ffffff',
    accent: '#3b82f6',
  },
  brandStyle: '',
  paymentGateways: [],
  otherGateway: '',
  personality: [],
};

export const MainForm: React.FC = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [showingCompletion, setShowingCompletion] = useState(false);

  const totalSteps = 7;

  const updateFormData = (field: keyof FormData, value: any) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleBack = () => {
    if (showingCompletion) {
      setShowingCompletion(false);
    } else {
      setStep(prev => Math.max(prev - 1, 1));
    }
  };

  const handleComplete = () => {
    setShowingCompletion(true);
  };

  const renderStep = () => {
    if (showingCompletion) {
      return (
        <div className="space-y-8">
          <Completion formData={formData} onBack={handleBack}>
            <SubmitButton formData={formData} />
          </Completion>
        </div>
      );
    }

    switch (step) {
      case 1:
        return <PersonalInfo formData={formData} updateFormData={updateFormData} />;
      case 2:
        return <StoreFocus formData={formData} updateFormData={updateFormData} />;
      case 3:
        return <StoreName formData={formData} updateFormData={updateFormData} />;
      case 4:
        return <BrandColors formData={formData} updateFormData={updateFormData} />;
      case 5:
        return <BrandRepresentation formData={formData} updateFormData={updateFormData} />;
      case 6:
        return <DesignElements formData={formData} updateFormData={updateFormData} />;
      case 7:
        return <StoreSettings formData={formData} updateFormData={updateFormData} onComplete={handleComplete} />;
      default:
        return null;
    }
  };

  const canProceed = () => {
    switch (step) {
      case 1:
        return formData.fullName && formData.email && formData.whatsapp;
      case 2:
        return formData.storeFocus && 
               (formData.storeFocus === 'Loja Geral' || formData.nicheMarket) &&
               formData.region &&
               (formData.region !== 'other' || formData.customRegion) &&
               formData.personality.length > 0;
      case 3:
        return formData.storeName;
      case 4:
        return formData.colors.primary && formData.colors.secondary && formData.colors.accent;
      case 5:
        return formData.brandStyle;
      case 6:
        return true;
      case 7:
        return formData.termsAccepted;
      default:
        return true;
    }
  };

  const isLastStep = step === totalSteps;
  const showProgress = step <= totalSteps && !showingCompletion;

  return (
    <div className="min-h-screen bg-gradient-animated text-white relative">
      <ParticleBackground />
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_top,rgba(12,12,44,0.3),transparent)]" />
      
      <Header />
      
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="container mx-auto px-4 py-24 md:py-32 min-h-screen flex items-center"
      >
        <div className="w-full max-w-2xl mx-auto gradient-border">
          <div className="glass-card p-4 md:p-8">
            {showProgress && (
              <div className="mb-6 md:mb-8">
                <ProgressBar currentStep={step} totalSteps={totalSteps} />
              </div>
            )}

            <AnimatePresence mode="wait">
              <motion.div
                key={showingCompletion ? 'completion' : step}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3 }}
                className="min-h-[400px] flex flex-col gradient-glow"
              >
                {renderStep()}

                {!showingCompletion && step <= totalSteps && (
                  <div className="mt-auto pt-6 md:pt-8 flex flex-col sm:flex-row gap-4 sm:gap-0 justify-between">
                    {step > 1 && (
                      <button
                        onClick={handleBack}
                        className="glass-button w-full sm:w-auto text-center"
                      >
                        Voltar
                      </button>
                    )}
                    {!isLastStep && (
                      <button
                        onClick={() => setStep(prev => prev + 1)}
                        disabled={!canProceed()}
                        className={`button-modern w-full sm:w-auto text-center ${
                          !canProceed() && 'opacity-50 cursor-not-allowed'
                        }`}
                      >
                        Continuar
                      </button>
                    )}
                  </div>
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </motion.div>

      <Footer />
    </div>
  );
};