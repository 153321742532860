import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { 
  X, 
  Save, 
  AlertCircle, 
  Users, 
  Link, 
  Calendar,
  Palette,
  Settings,
  Info,
  CheckCircle2,
  Clock,
  AlertTriangle,
  Loader2,
  Plus,
  Share2, 
  Mail, 
  Phone, 
  Calendar as CalendarIcon,
  XCircle
} from 'lucide-react';
import { useProductionStore, type ProductionItem } from '../../stores/productionStore';
import { cn } from '../../lib/utils';
import { format, isValid } from 'date-fns';

interface Props {
  item: ProductionItem;
  onClose: () => void;
}

const StatusBadge: React.FC<{ status: string }> = ({ status }) => {
  return (
    <div className={cn(
      "flex items-center gap-2 px-3 py-1.5 rounded-lg text-sm font-medium w-full transition-colors",
      status === 'not_started' && "bg-gray-500/10 text-gray-400 hover:bg-gray-500/20",
      status === 'in_progress' && "bg-blue-500/10 text-blue-500 hover:bg-blue-500/20",
      status === 'completed' && "bg-green-500/10 text-green-500 hover:bg-green-500/20"
    )}>
      {status === 'not_started' && <AlertTriangle className="w-4 h-4" />}
      {status === 'in_progress' && <Loader2 className="w-4 h-4 animate-spin" />}
      {status === 'completed' && <CheckCircle2 className="w-4 h-4" />}
      <span>
        {status === 'not_started' && 'Não Iniciado'}
        {status === 'in_progress' && 'Em Andamento'}
        {status === 'completed' && 'Concluído'}
      </span>
    </div>
  );
};

const Section: React.FC<{
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
}> = ({ title, icon, children }) => (
  <div className="space-y-4 p-4 bg-gray-800/30 rounded-lg border border-gray-700">
    <div className="flex items-center gap-2 text-gray-300">
      {icon}
      <h4 className="font-medium">{title}</h4>
    </div>
    {children}
  </div>
);

const TeamMemberTag: React.FC<{
  name: string;
  onRemove: () => void;
}> = ({ name, onRemove }) => (
  <div className="flex items-center gap-2 px-3 py-1.5 bg-blue-500/20 text-blue-300 rounded-lg">
    <Users className="w-4 h-4" />
    <span>{name}</span>
    <button
      onClick={onRemove}
      className="text-blue-300 hover:text-blue-200"
    >
      <XCircle className="w-4 h-4" />
    </button>
  </div>
);

export const EditStoreModal: React.FC<Props> = ({ item, onClose }) => {
  const [formData, setFormData] = useState(item);
  const [error, setError] = useState<string | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const updateItem = useProductionStore((state) => state.updateItem);
  const [teamMembers] = useState([
    'João Silva',
    'Maria Santos',
    'Pedro Alves',
    'Ana Costa',
    'Lucas Mendes'
  ]);

  const formatDate = (date: string | undefined) => {
    if (!date) return '';
    const parsedDate = new Date(date);
    return isValid(parsedDate) ? format(parsedDate, 'yyyy-MM-dd') : '';
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Check if all statuses are completed
      const allStatuses = Object.values(formData.status);
      const allCompleted = allStatuses.every(status => status === 'completed');
      
      if (allCompleted) {
        // Update the store status to completed
        updateItem(item.id, {
          ...formData,
          storeStatus: 'completed'
        });
      } else {
        updateItem(item.id, formData);
      }
      
      onClose();
    } catch (err) {
      setError('Erro ao atualizar loja');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        handleSubmit(new Event('submit') as unknown as React.FormEvent);
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const designStatuses = {
    visualIdentity: 'Identidade Visual',
    banners: 'Banners',
    information: 'Informativos',
    collectionCovers: 'Capas de Coleção',
    motion: 'Motion',
    socialMedia: 'Social Media'
  };

  const configStatuses = {
    checkout: 'Checkout',
    refinement: 'Refino',
    storeConfig: 'Configuração da Loja'
  };

  const handleStatusChange = (key: string) => {
    const currentStatus = formData.status[key as keyof typeof formData.status];
    const nextStatus = {
      not_started: 'in_progress',
      in_progress: 'completed',
      completed: 'not_started'
    }[currentStatus === 'pending' ? 'not_started' : currentStatus];
    
    setFormData({
      ...formData,
      status: {
        ...formData.status,
        [key]: nextStatus
      }
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 p-4"
    >
      <motion.div
        ref={modalRef}
        initial={{ scale: 0.95 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.95 }}
        className="bg-gray-900 rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto border border-gray-800 shadow-xl relative scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-800/50"
      >
        <form onSubmit={handleSubmit} className="flex flex-col">
          <div className="p-6 space-y-6">
            <div className="grid grid-cols-2 gap-6">
              {/* Design Section */}
              <Section title="Design" icon={<Palette className="w-4 h-4" />}>
                <div className="space-y-3">
                  {Object.entries(designStatuses).map(([key, label]) => (
                    <div key={key}>
                      <label className="block text-sm text-gray-400 mb-1">
                        {label}
                      </label>
                      <button
                        type="button"
                        onClick={() => handleStatusChange(key)}
                      >
                        <StatusBadge 
                          status={formData.status[key as keyof typeof formData.status] === 'pending' 
                            ? 'not_started' 
                            : formData.status[key as keyof typeof formData.status]} 
                        />
                      </button>
                    </div>
                  ))}
                </div>
              </Section>

              {/* Configuration Section */}
              <Section title="Configuração" icon={<Settings className="w-4 h-4" />}>
                <div className="space-y-3">
                  {Object.entries(configStatuses).map(([key, label]) => (
                    <div key={key}>
                      <label className="block text-sm text-gray-400 mb-1">
                        {label}
                      </label>
                      <button
                        type="button"
                        onClick={() => handleStatusChange(key)}
                      >
                        <StatusBadge 
                          status={formData.status[key as keyof typeof formData.status] === 'pending' 
                            ? 'not_started' 
                            : formData.status[key as keyof typeof formData.status]} 
                        />
                      </button>
                    </div>
                  ))}
                </div>
              </Section>

              {/* Team Section */}
              <Section title="Responsáveis" icon={<Users className="w-4 h-4" />}>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm text-gray-400 mb-1">
                      Designer
                    </label>
                    <div className="relative">
                      <select
                        value={formData.responsibleDesigner || ''}
                        onChange={(e) => setFormData({ ...formData, responsibleDesigner: e.target.value })}
                        className="w-full px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-white appearance-none"
                      >
                        <option value="">Selecione um designer</option>
                        {teamMembers.map(member => (
                          <option key={member} value={member}>{member}</option>
                        ))}
                      </select>
                      <Users className="absolute right-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm text-gray-400 mb-1">
                      Desenvolvedor
                    </label>
                    <div className="relative">
                      <select
                        value={formData.responsibleDeveloper || ''}
                        onChange={(e) => setFormData({ ...formData, responsibleDeveloper: e.target.value })}
                        className="w-full px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-white appearance-none"
                      >
                        <option value="">Selecione um desenvolvedor</option>
                        {teamMembers.map(member => (
                          <option key={member} value={member}>{member}</option>
                        ))}
                      </select>
                      <Users className="absolute right-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
                    </div>
                  </div>
                </div>
              </Section>

              {/* Social Media Section */}
              <Section title="Social Media" icon={<Share2 className="w-4 h-4" />}>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm text-gray-400 mb-1">
                      Gestor Responsável
                    </label>
                    <div className="relative">
                      <select
                        value={formData.socialMedia?.manager || ''}
                        onChange={(e) => setFormData({
                          ...formData,
                          socialMedia: {
                            ...formData.socialMedia,
                            manager: e.target.value
                          }
                        })}
                        className="w-full px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-white appearance-none"
                      >
                        <option value="">Selecione um gestor</option>
                        {teamMembers.map(member => (
                          <option key={member} value={member}>{member}</option>
                        ))}
                      </select>
                      <Users className="absolute right-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm text-gray-400 mb-1">
                      E-mail Profissional
                    </label>
                    <div className="relative">
                      <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-4 h-4" />
                      <input
                        type="email"
                        value={formData.socialMedia?.email || ''}
                        onChange={(e) => setFormData({
                          ...formData,
                          socialMedia: {
                            ...formData.socialMedia,
                            email: e.target.value
                          }
                        })}
                        className="w-full pl-10 pr-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-white"
                        placeholder="exemplo@empresa.com"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm text-gray-400 mb-1">
                      Telefone
                    </label>
                    <div className="relative">
                      <Phone className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-4 h-4" />
                      <input
                        type="tel"
                        value={formData.socialMedia?.phone || ''}
                        onChange={(e) => setFormData({
                          ...formData,
                          socialMedia: {
                            ...formData.socialMedia,
                            phone: e.target.value
                          }
                        })}
                        className="w-full pl-10 pr-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-white"
                        placeholder="(00) 00000-0000"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm text-gray-400 mb-1">
                      Horário Disponível
                    </label>
                    <div className="relative">
                      <CalendarIcon className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-4 h-4" />
                      <input
                        type="text"
                        value={formData.socialMedia?.availableHours || ''}
                        onChange={(e) => setFormData({
                          ...formData,
                          socialMedia: {
                            ...formData.socialMedia,
                            availableHours: e.target.value
                          }
                        })}
                        className="w-full pl-10 pr-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-white"
                        placeholder="09:00 às 18:00"
                      />
                    </div>
                  </div>

                  <div className="space-y-2">
                    <label className="block text-sm text-gray-400">
                      Aceita faturamento via boleto?
                    </label>
                    <div className="flex items-center gap-4">
                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          checked={formData.socialMedia?.acceptsBilling === true}
                          onChange={() => setFormData({
                            ...formData,
                            socialMedia: {
                              ...formData.socialMedia,
                              acceptsBilling: true
                            }
                          })}
                          className="w-4 h-4 text-blue-500"
                        />
                        <span className="text-white">Sim</span>
                      </label>
                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          checked={formData.socialMedia?.acceptsBilling === false}
                          onChange={() => setFormData({
                            ...formData,
                            socialMedia: {
                              ...formData.socialMedia,
                              acceptsBilling: false
                            }
                          })}
                          className="w-4 h-4 text-blue-500"
                        />
                        <span className="text-white">Não</span>
                      </label>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <label className="block text-sm text-gray-400">
                      Status do Projeto
                    </label>
                    <select
                      value={formData.socialMedia?.projectStatus || ''}
                      onChange={(e) => setFormData({
                        ...formData,
                        socialMedia: {
                          ...formData.socialMedia,
                          projectStatus: e.target.value
                        }
                      })}
                      className="w-full px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-white"
                    >
                      <option value="">Selecione o status</option>
                      <option value="not_started">Não Iniciado - Briefing em análise</option>
                      <option value="in_production">Em Produção - Criação em andamento</option>
                      <option value="finished">Finalizado - Pronto para publicação</option>
                    </select>
                  </div>

                  <div className="space-y-4">
                    <h4 className="text-sm font-medium text-white">Etapas de Execução</h4>
                    
                    <div className="space-y-4">
                      {[/* ... */].map((step) => (
                        <div key={step.key} className="space-y-2">
                          <label className="block text-sm text-gray-400">
                            {step.label}
                          </label>
                          <select
                            value={formData.socialMedia?.steps?.[step.key] || ''}
                            onChange={(e) => setFormData({
                              ...formData,
                              socialMedia: {
                                ...formData.socialMedia,
                                steps: {
                                  ...formData.socialMedia?.steps,
                                  [step.key]: e.target.value
                                }
                              }
                            })}
                            className="w-full px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-white"
                          >
                            <option value="">Selecione o status</option>
                            <option value="pending">Pendente</option>
                            <option value="in_progress">Em Andamento</option>
                            <option value="completed">Concluído</option>
                          </select>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm text-gray-400 mb-1">
                      Notas Importantes
                    </label>
                    <textarea
                      value={formData.socialMedia?.notes || ''}
                      onChange={(e) => setFormData({
                        ...formData,
                        socialMedia: {
                          ...formData.socialMedia,
                          notes: e.target.value
                        }
                      })}
                      className="w-full px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-white resize-none"
                      placeholder="Informações adicionais relevantes para o projeto..."
                      rows={4}
                      maxLength={500}
                    />
                  </div>
                </div>
              </Section>

              {/* Information Section */}
              <Section title="Informações" icon={<Info className="w-4 h-4" />}>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm text-gray-400 mb-1">
                      Link do Drive
                    </label>
                    <input
                      type="url"
                      value={formData.driveLink || ''}
                      onChange={(e) => setFormData({ ...formData, driveLink: e.target.value })}
                      className="w-full px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-white"
                      placeholder="https://drive.google.com/..."
                    />
                  </div>

                  <div>
                    <label className="block text-sm text-gray-400 mb-1">
                      Link da Loja
                    </label>
                    <input
                      type="url"
                      value={formData.completedStoreLink || ''}
                      onChange={(e) => setFormData({ ...formData, completedStoreLink: e.target.value })}
                      className="w-full px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-white"
                      placeholder="https://loja.myshopify.com"
                    />
                  </div>

                  <div>
                    <label className="block text-sm text-gray-400 mb-1">
                      Data de Entrega
                    </label>
                    <input
                      type="date"
                      value={formatDate(formData.expectedDeliveryDate)}
                      onChange={(e) => setFormData({ 
                        ...formData, 
                        expectedDeliveryDate: e.target.value ? new Date(e.target.value).toISOString() : undefined 
                      })}
                      className="w-full px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-white"
                    />
                  </div>
                </div>
              </Section>
            </div>
          </div>

          <div className="sticky bottom-0 left-0 right-0 bg-gray-900 border-t border-gray-800 p-4 flex justify-end gap-3 shadow-lg">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-300 hover:text-white transition-colors"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="flex items-center gap-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors"
            >
              <Save className="w-4 h-4" />
              Salvar Alterações
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};