import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface Achievement {
  id: string;
  name: string;
  description: string;
  icon: string;
  type: 'task' | 'feedback' | 'streak' | 'special';
  rarity: 'common' | 'rare' | 'epic' | 'legendary';
  xpReward: number;
  condition: {
    type: 'count' | 'streak' | 'rating';
    value: number;
  };
}

export interface MemberProgress {
  memberId: string;
  level: number;
  xp: number;
  achievements: string[]; // IDs das conquistas
  stats: {
    tasksCompleted: number;
    feedbackScore: number;
    currentStreak: number;
    bestStreak: number;
  };
}

interface AchievementStore {
  achievements: Achievement[];
  progress: MemberProgress[];
  addAchievement: (achievement: Omit<Achievement, 'id'>) => void;
  unlockAchievement: (memberId: string, achievementId: string) => void;
  addXP: (memberId: string, amount: number) => void;
  updateStats: (memberId: string, stats: Partial<MemberProgress['stats']>) => void;
  getMemberProgress: (memberId: string) => MemberProgress | undefined;
  initializeMember: (memberId: string) => void;
}

const calculateLevel = (xp: number): number => {
  // Fórmula: level = floor(sqrt(xp / 100))
  return Math.floor(Math.sqrt(xp / 100));
};

const INITIAL_ACHIEVEMENTS: Achievement[] = [
  {
    id: 'first-task',
    name: 'Primeira Missão',
    description: 'Complete sua primeira tarefa',
    icon: '🎯',
    type: 'task',
    rarity: 'common',
    xpReward: 100,
    condition: { type: 'count', value: 1 }
  },
  {
    id: 'task-master',
    name: 'Mestre das Tarefas',
    description: 'Complete 100 tarefas',
    icon: '👑',
    type: 'task',
    rarity: 'epic',
    xpReward: 1000,
    condition: { type: 'count', value: 100 }
  },
  {
    id: 'perfect-streak',
    name: 'Perfeição',
    description: 'Mantenha uma sequência de 7 dias de entregas no prazo',
    icon: '🔥',
    type: 'streak',
    rarity: 'rare',
    xpReward: 500,
    condition: { type: 'streak', value: 7 }
  },
  {
    id: 'client-favorite',
    name: 'Favorito dos Clientes',
    description: 'Receba 10 avaliações 5 estrelas',
    icon: '⭐',
    type: 'feedback',
    rarity: 'legendary',
    xpReward: 2000,
    condition: { type: 'rating', value: 10 }
  }
];

export const useAchievementStore = create<AchievementStore>()(
  persist(
    (set, get) => ({
      achievements: INITIAL_ACHIEVEMENTS,
      progress: [],
      
      addAchievement: (achievement) => set((state) => ({
        achievements: [...state.achievements, { ...achievement, id: crypto.randomUUID() }]
      })),

      unlockAchievement: (memberId, achievementId) => set((state) => {
        const memberProgress = state.progress.find(p => p.memberId === memberId);
        if (!memberProgress || memberProgress.achievements.includes(achievementId)) {
          return state;
        }

        const achievement = state.achievements.find(a => a.id === achievementId);
        if (!achievement) return state;

        // Adiciona XP e atualiza conquistas
        const updatedProgress = state.progress.map(p =>
          p.memberId === memberId
            ? {
                ...p,
                xp: p.xp + achievement.xpReward,
                level: calculateLevel(p.xp + achievement.xpReward),
                achievements: [...p.achievements, achievementId]
              }
            : p
        );

        return { ...state, progress: updatedProgress };
      }),

      addXP: (memberId, amount) => set((state) => {
        const memberProgress = state.progress.find(p => p.memberId === memberId);
        if (!memberProgress) return state;

        const updatedProgress = state.progress.map(p =>
          p.memberId === memberId
            ? {
                ...p,
                xp: p.xp + amount,
                level: calculateLevel(p.xp + amount)
              }
            : p
        );

        return { ...state, progress: updatedProgress };
      }),

      updateStats: (memberId, stats) => set((state) => {
        const memberProgress = state.progress.find(p => p.memberId === memberId);
        if (!memberProgress) return state;

        const updatedProgress = state.progress.map(p =>
          p.memberId === memberId
            ? {
                ...p,
                stats: { ...p.stats, ...stats }
              }
            : p
        );

        return { ...state, progress: updatedProgress };
      }),

      getMemberProgress: (memberId) => {
        return get().progress.find(p => p.memberId === memberId);
      },

      initializeMember: (memberId) => set((state) => {
        if (state.progress.some(p => p.memberId === memberId)) {
          return state;
        }

        return {
          ...state,
          progress: [
            ...state.progress,
            {
              memberId,
              level: 1,
              xp: 0,
              achievements: [],
              stats: {
                tasksCompleted: 0,
                feedbackScore: 0,
                currentStreak: 0,
                bestStreak: 0
              }
            }
          ]
        };
      })
    }),
    {
      name: 'achievements-storage'
    }
  )
);