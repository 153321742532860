import React from 'react';
import { motion } from 'framer-motion';
import { Sparkles, ArrowLeft, Download } from 'lucide-react';
import { FormData } from '../../App';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface Props {
  formData: FormData;
  onBack: () => void;
  children?: React.ReactNode;
}

const getCompletionMessage = (region: string, customRegion?: string) => {
  const messages = {
    brazil: 'Sua marca está pronta para brilhar! 🌟',
    latam: '¡Tu marca está lista para brillar! 🌟',
    europe: 'Your brand is ready to shine! 🌟',
    other: 'Your brand is ready to shine! 🌟'
  };
  return messages[region as keyof typeof messages] || messages.other;
};

export const Completion: React.FC<Props> = ({ formData, onBack, children }) => {
  const summaryRef = React.useRef<HTMLDivElement>(null);

  const generatePDF = async () => {
    if (!summaryRef.current) return;

    try {
      const canvas = await html2canvas(summaryRef.current, {
        scale: 2,
        logging: false,
        useCORS: true,
        backgroundColor: '#111827'
      });

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      
      const pdf = new jsPDF({
        orientation: imgHeight > imgWidth ? 'portrait' : 'landscape',
        unit: 'mm',
        format: 'a4',
      });

      const imgData = canvas.toDataURL('image/png');
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

      pdf.save(`vision-branding-${formData.storeName}-${format(new Date(), 'dd-MM-yyyy')}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-8"
    >
      <div className="flex items-center justify-between">
        <button
          onClick={onBack}
          className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors"
        >
          <ArrowLeft className="w-4 h-4" />
          <span>Voltar</span>
        </button>
        <button
          onClick={generatePDF}
          className="flex items-center gap-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors"
        >
          <Download className="w-4 h-4" />
          <span>Baixar PDF</span>
        </button>
      </div>

      <div ref={summaryRef} className="space-y-8 p-8">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", duration: 0.5 }}
          className="mx-auto w-20 h-20 bg-blue-500/20 rounded-full flex items-center justify-center"
        >
          <Sparkles className="w-10 h-10 text-blue-500" />
        </motion.div>

        <div className="text-center space-y-2">
          <h2 className="text-3xl font-bold text-white">
            {getCompletionMessage(formData.region, formData.customRegion)}
          </h2>
          <p className="text-gray-400">
            Obrigado por compartilhar sua visão conosco, {formData.fullName?.split(' ')[0]}!
          </p>
        </div>

        <div className="bg-gray-800/50 rounded-lg p-8 space-y-6 border border-gray-700">
          <div className="space-y-4">
            <h3 className="text-xl font-bold text-white">Resumo do Projeto</h3>
            
            <div className="space-y-6">
              <div>
                <h4 className="text-sm font-medium text-gray-400 mb-2">Informações da Loja</h4>
                <div className="space-y-2">
                  <p className="text-lg font-medium text-white">{formData.storeName}</p>
                  <p className="text-gray-300">
                    {formData.storeFocus}
                    {formData.nicheMarket && ` • ${formData.nicheMarket}`}
                  </p>
                  <p className="text-gray-300">
                    Região: {formData.customRegion || formData.region}
                  </p>
                </div>
              </div>

              <div>
                <h4 className="text-sm font-medium text-gray-400 mb-2">Paleta de Cores</h4>
                <div className="flex gap-4">
                  {Object.entries(formData.colors).map(([key, color]) => (
                    <div key={key} className="text-center">
                      <div
                        className="w-12 h-12 rounded-lg border border-gray-600"
                        style={{ backgroundColor: color }}
                      />
                      <span className="text-xs text-gray-400 mt-1 block capitalize">
                        {key}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <h4 className="text-sm font-medium text-gray-400 mb-2">Personalidade da Marca</h4>
                <div className="flex flex-wrap gap-2">
                  {formData.personality.map((trait) => (
                    <span
                      key={trait}
                      className="px-3 py-1 rounded-full bg-blue-500/20 text-blue-300 text-sm"
                    >
                      {trait}
                    </span>
                  ))}
                </div>
              </div>

              {formData.brandDescription && (
                <div>
                  <h4 className="text-sm font-medium text-gray-400 mb-2">Descrição da Marca</h4>
                  <p className="text-gray-300">{formData.brandDescription}</p>
                </div>
              )}

              {formData.bannerContent && formData.bannerContent.length > 0 && (
                <div>
                  <h4 className="text-sm font-medium text-gray-400 mb-2">Banners</h4>
                  <div className="space-y-2">
                    {formData.bannerContent.map((banner: any, index: number) => (
                      banner.content && (
                        <div key={index} className="bg-gray-700/50 p-3 rounded-lg">
                          <p className="text-sm text-white">{banner.content}</p>
                        </div>
                      )
                    ))}
                  </div>
                </div>
              )}

              <div>
                <h4 className="text-sm font-medium text-gray-400 mb-2">Configurações Técnicas</h4>
                <div className="space-y-2">
                  {formData.paymentGateways && (
                    <div className="flex flex-wrap gap-2">
                      {formData.paymentGateways.map((gateway) => (
                        <span key={gateway} className="px-3 py-1 bg-gray-700/50 text-white rounded-lg text-sm">
                          {gateway}
                        </span>
                      ))}
                    </div>
                  )}
                  {formData.socialMedia && Object.entries(formData.socialMedia).length > 0 && (
                    <div className="flex flex-wrap gap-2 mt-2">
                      {Object.entries(formData.socialMedia).map(([platform, url]) => (
                        url && (
                          <span key={platform} className="px-3 py-1 bg-gray-700/50 text-white rounded-lg text-sm">
                            {platform}
                          </span>
                        )
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="pt-8 border-t border-gray-700">
            <div className="flex justify-between items-center">
              <div>
                <img
                  src="https://woopremium.site/wp-content/uploads/2024/11/Logo-Branco.png.webp"
                  alt="Sabino Vision"
                  className="h-8 w-auto"
                />
                <p className="text-sm text-gray-400 mt-2">
                  Transformando visões em realidade digital
                </p>
              </div>
              <div className="text-right">
                <p className="text-sm text-gray-400">Data</p>
                <p className="text-white">
                  {format(new Date(), "dd 'de' MMMM 'de' yyyy", { locale: ptBR })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {children && (
        <div className="mt-8">
          {children}
        </div>
      )}
    </motion.div>
  );
};