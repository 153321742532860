import React from 'react';
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  Node,
  Edge
} from 'react-flow-renderer';
import { AutomationRule } from '../../services/automations/types';

interface Props {
  rule: AutomationRule;
}

export const AutomationFlow: React.FC<Props> = ({ rule }) => {
  const nodes: Node[] = [
    {
      id: 'trigger',
      type: 'input',
      data: { label: `Trigger: ${rule.trigger}` },
      position: { x: 250, y: 25 },
      style: {
        background: 'rgba(59, 130, 246, 0.1)',
        border: '1px solid rgb(59, 130, 246)',
        color: 'white'
      }
    },
    ...rule.actions.map((action, index) => ({
      id: `action-${index}`,
      data: { label: `Action: ${action.type}` },
      position: { x: 250, y: 125 + (index * 100) },
      style: {
        background: 'rgba(16, 185, 129, 0.1)',
        border: '1px solid rgb(16, 185, 129)',
        color: 'white'
      }
    }))
  ];

  const edges: Edge[] = rule.actions.map((_, index) => ({
    id: `edge-${index}`,
    source: index === 0 ? 'trigger' : `action-${index - 1}`,
    target: `action-${index}`,
    animated: true,
    style: { stroke: '#3b82f6' }
  }));

  return (
    <div className="h-full">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        fitView
        defaultEdgeOptions={{
          type: 'smoothstep'
        }}
      >
        <Background color="#374151" gap={16} />
        <Controls />
        <MiniMap
          nodeStrokeColor="#3b82f6"
          nodeColor="#1f2937"
          nodeBorderRadius={2}
        />
      </ReactFlow>
    </div>
  );
};