import React from 'react';
import { Shield } from 'lucide-react';
import type { Rank } from '../../types/team';

interface Props {
  rank?: Rank;
  size?: 'sm' | 'md' | 'lg';
}

const rankConfig = {
  'ferro': {
    icon: Shield,
    color: 'text-gray-400',
    bgColor: 'bg-gray-400/10',
    borderColor: 'border-gray-400/20',
    label: 'Ferro'
  },
  'prata': {
    icon: Shield,
    color: 'text-gray-300',
    bgColor: 'bg-gray-300/10',
    borderColor: 'border-gray-300/20',
    label: 'Prata'
  },
  'ouro': {
    icon: Shield,
    color: 'text-yellow-400',
    bgColor: 'bg-yellow-400/10',
    borderColor: 'border-yellow-400/20',
    label: 'Ouro'
  },
  'diamante': {
    icon: Shield,
    color: 'text-blue-400',
    bgColor: 'bg-blue-400/10',
    borderColor: 'border-blue-400/20',
    label: 'Diamante'
  }
} as const;

export const RankBadge: React.FC<Props> = ({ rank = 'ferro', size = 'md' }) => {
  const config = rankConfig[rank];

  // Se não encontrar o rank, usa o ferro como padrão
  if (!config) {
    console.warn(`Rank "${rank}" não encontrado, usando ferro como padrão`);
    return <RankBadge rank="ferro" size={size} />;
  }

  const Icon = config.icon;

  const sizeClasses = {
    sm: 'text-xs px-2 py-1',
    md: 'text-sm px-3 py-1.5',
    lg: 'text-base px-4 py-2'
  };

  const iconSizes = {
    sm: 'w-3 h-3',
    md: 'w-4 h-4',
    lg: 'w-5 h-5'
  };

  return (
    <div
      className={`
        inline-flex items-center gap-1.5 rounded-full border
        ${config.bgColor}
        ${config.borderColor}
        ${sizeClasses[size]}
      `}
    >
      <Icon className={`${iconSizes[size]} ${config.color}`} />
      <span className={config.color}>{config.label}</span>
    </div>
  );
};